
$(document).ready(function() {

    if ($(".page_ticket").length) {

        $('.see_more_btn').click(function (){

            let id = $(this).attr("id").split("_")[1];
            let eltDot = $("#dots_"+id);
            let eltBtn= $("#seemore_"+id);
            let eltTxt = $("#ticket_seemore_"+id);
            if(eltDot.is(":hidden")){
                eltDot.css("display","inline");
                eltBtn.html("Voir plus");
                eltTxt.css("display","none");
            }
            else {
                eltDot.css("display","none");
                eltBtn.html("Voir moins");
                eltTxt.css("display","inline");
            }

        });

        $('.new_message').click(function (){
            $('#modal_new_message').modal('show');
        });

        $('.internal_note').click(function (){
            $('#modal_internal_note').modal('show');
        });

        $('.affect_ticket').click(function (){
            $('#modal_affect_ticket').modal('show');
        });

        $('.change_status').click(function (){
            $('#modal_change_status').modal('show');
        });

        $('.change_customer').click(function (){
            $('#modal_change_customer').modal('show');
        });

        $('.delete_ticket').click(function (){
            $('#modal_delete_ticket').modal('show');
        });

    }

});