require('../css/app.css');

require("./bar-chart");
require("./jquery.slicknav.min");
require("./jquery.slimscroll.min");
require("./line-chart");
//require("./maps");
require("./metisMenu.min");

require('imports-loader?this=>window!./modernizr-2.8.3.min');

require("./owl.carousel.min");
require("./pie-chart");
require("./plugins");

require('./daterangepicker');

require('./jquery-ui');


require("./search");
require("./searchProspect");
require("./popper.min")
require("./bootstrap.min");

require("./scripts");
require("./campagne");
require("./bloctel_depot")
require("./client")
require("sweetalert");
require("./prospect");
require("./user");
require("./dashboard");
require('./analyse');
require('./conversation');
require('./error_api');
require('./ticket');
require("./bootstrap-datepicker.min");
require("./bootstrap-datepicker.fr.min");

require.context("../images", false, /\.(png|jpg|svg)$/);

// import the function from greet.js (the .js extension is optional)
// ./ (or ../) means to look for a local file
// var dropdown = require("./components/dropdown.js");

const $ = require("jquery");
global.$ = global.jQuery = $;
