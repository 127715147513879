
$(document).ready(function() {



    function formatDate(date) {

        var month = date.getMonth()+1;
        if(month < 10)
            month = "0"+month;

        return date.getDate()+"/"+month+"/"+date.getFullYear()
    }

    if ($(".page_dashboard").length) {

        $('.open_modal_rpt_nb_day').click(function (e){

           let brandId = $(this).attr("id").split("_")[2];
            $('body').css("opacity", "0.4");

            $.ajax({
                type: "POST",
                url: "/getReportNewClient",
                dataType: 'json',
                data: {brand_id: brandId},
                error: function (jqXHR, textStatus, errorThrown) {
                    $('body').css("opacity", "1");
                    console.log(errorThrown);
                    alert(errorThrown);
                },
                success: function (result) {
                    $('body').css("opacity", "1");
                    var html = "<table cellpadding=\"10\" cellspacing=\"10\" border=\"0\"><tr><th>Jour</th><th>En cours</th><th>Total</th></tr>";
                    if(result.hasOwnProperty("nb_saved_per_day")){
                        $.each(result['nb_saved_per_day'],function (elt, val){
                            html += "<tr><td>"+elt+"</td><td><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"+result['nb_saved_per_day'][elt][brandId]['nb_en_cours']+"</b></td><td><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"+result['nb_saved_per_day'][elt][brandId]['nb_total']+"</b></td></tr>";
                        });
                    }

                    html += "</table>";
                    $('#rpt_content_'+brandId).html(html);
                    $('#rpt_content_'+brandId).parent().show();
                }
            });

        });

        $('.close_modal_btn').click(function (e){
            $(this).parent().hide();
        });

        $(function () {
            $("#cfast_file").change(function () {
                let label = $("label[for='" + $(this).attr('id') + "']");
                if(document.getElementById("cfast_file").files[0] != null){
                    let fileName = document.getElementById("cfast_file").files[0].name;
                    label.html(fileName);
                }
                else {
                    label.html("Choisir un fichier");
                }

            });
        });


        $('form.depot_bt_file').submit(function () {
            $('body').css("opacity","0.4");
        });

        $('#is_porta').change(function() {
            if($(this).is(":checked")) {
                $("#date_porta_div").show();
                $("#date_exec_div").hide();
            }
            else {
                $("#date_porta_div").hide();
                $("#date_exec_div").show();
            }
        });

        Date.prototype.addDays = function(days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        }

        var dateToday = new Date();


        $("#date_exec").datepicker({
            language: 'fr',
            startDate: dateToday,
        });

        $("#date_exec").val(formatDate(dateToday));

        /*if(dateToday.addDays(3).getDay() === 0)
            $("#date_porta").val(formatDate(dateToday.addDays(4)));
        else $("#date_porta").val(formatDate(dateToday.addDays(3)));*/


        $('.cfast_logo').click(function (e) {

            let clientId = $(this).attr("id").split('_')[2];
            let clientName =$("#synchro_cfast_"+clientId).find( "input[name=client_name]").val();

            swal({
                title: "Provisionning CFAST",
                text: "Enregistrer le client "+clientName+" dans CFAST ",
                icon: "",
                buttons: true,
                dangerMode: false,
            })
            .then((confirm) => {
                if (confirm) {

                    $('body').css("opacity","0.4");

                    $.ajax({
                        type: "POST",
                        url: "/client_info_for_cfast",
                        dataType : 'json',
                        data: {clientId:clientId},
                        error: function(jqXHR, textStatus, errorThrown) {
                            $('body').css("opacity","1");
                            console.log(errorThrown);
                            alert(errorThrown);
                        },
                        success: function(result) {

                            $('body').css("opacity","1");

                            if(result['has_msisdn']){
                                $("#is_porta_div").show();
                                $("#msisdn_info").hide();
                                if(result['has_rio']){
                                    $("#is_porta").prop('checked', true);
                                    $("#date_porta_div").show();
                                    $("#date_exec_div").hide();
                                }
                                else {
                                    $("#is_porta").prop('checked', false);
                                    $("#date_porta_div").hide();
                                    $("#date_exec_div").show();
                                }

                                var newSimStr = "";
                                if(result['new_eit_sim']) {
                                    newSimStr = "<div class='green_color'><span class=\"ti-check\"></span> Fait partie du lot des nouvelles SIM</div>";
                                }
                                else {
                                    newSimStr = "<div class='red_color'><span class=\"ti-close\"></span> Ne fait pas partie du lot des nouvelles SIM</div>";
                                }

                                var eitValidStr = "";
                                if(result['sim_cfast_valid']){
                                    eitValidStr = "<div class='green_color'><span class=\"ti-check\"></span> Disponible chez CFAST</div>";
                                }
                                else {
                                    eitValidStr = "<div class='red_color'><span class=\"ti-close\"></span>  Indisponible chez CFAST</div>";
                                }

                                $("#simsn_info").html("SIMSN : "+result['simsn']+"<br/>"+newSimStr+eitValidStr);

                                $("#offer_info").html("Offre actuelle : "+result['offer']+"<br/>");
                                if(result['offer_to_map'] != null)
                                    $("#offer_info").html($("#offer_info").html()+"<div class='green_color'><span class=\"ti-check\"></span> Sera positionné sur le forfait CFAST : "+result['offer_to_map']+"</div>");
                                else
                                    $("#offer_info").html($("#offer_info").html()+"<div class='red_color'><span class=\"ti-close\"></span> Aucun mapping vers un forfait CFAST n'est défini pour cette offre</div>");

                                $('#cfast_offer_id').val(result['cfast_offer_id']);
                                $('#clientLineId').val(result['clientLineId']);
                                $('#cfast_sim_id').val(result['sim_id_cfast']);

                                if(result['cfast_offer_id'] !== -1){
                                    $("#offer_info").html($("#offer_info").html()+"<div class='green_color'><span class=\"ti-check\"></span> Offre CFAST : "+result['cfast_offer_id']+"</div>");
                                }
                                else {
                                    $("#offer_info").html($("#offer_info").html()+"<div class='red_color'><span class=\"ti-close\"></span> Offre mapping non reconnue par CFAST </div>");
                                }

                                if(result['new_eit_sim'] && result['sim_cfast_valid'] && result['offer_to_map'] != null && result['cfast_offer_id'] !== -1){
                                    $("#btn_cfast_send").show();
                                }
                                else {
                                    $("#btn_cfast_send").hide();
                                }
                            }
                            else {
                                // Pas de MSISDN par de provisionning
                                $("#is_porta_div").hide();
                                $("#date_porta_div").hide();
                                $("#date_exec_div").hide();
                                $("#msisdn_info").show();

                            }

                            $('#modal_cfast_send_1').modal("show");

                        }
                    });




                    //$("#synchro_cfast_"+clientId).submit();
                }
            });

        });


        $("#formCfastProv").submit(function (e) {
            e.preventDefault()

            $('body').css("opacity","0.4");

            $.ajax({
                type: "POST",
                url: $(this).attr('action'),
                dataType : 'json',
                data: $(this).serialize(),
                error: function(jqXHR, textStatus, errorThrown) {

                    $('body').css("opacity","1");
                    $('#modal_cfast_send_1').modal("hide");

                    swal({
                        title: "Echec Provisionning CFAST",
                        text: "Problème lors de l'envoi à CFAST, contactez votre administrateur",
                        icon: "",
                        buttons: false,
                        dangerMode: true,
                    });

                    console.log(errorThrown);

                },
                success: function(result) {
                    //console.log(result);
                    $('body').css("opacity","1");

                    $('#modal_cfast_send_1').modal("hide");

                    var response  = result;
                    if(response.hasOwnProperty("error")){
                        swal({
                            title: "Echec Provisionning CFAST",
                            text: response['error'],
                            icon: "",
                            buttons: false,
                            dangerMode: true,
                        });
                    }
                    else {
                        swal({
                            title: "Succès Provisionning CFAST",
                            text: response['error'],
                            icon: "",
                            buttons: false,
                            dangerMode: false,
                        });

                        $("tr").each(function() {
                            if($(this).attr("id") && $(this).attr("id").indexOf("_") !== -1){

                                var id = $(this).attr("id").split("_")[1];

                                if(id === $("#clientId").val()){
                                    $(this).hide();
                                    return false;
                                }
                            }

                        });

                    }
                }
            });

        });



        $('.gen_mails').click(function (e) {

            $('body').css("opacity","0.4");

            var idBrand = $(this).attr('id').split("_")[2];

            $.ajax({
                type: "POST",
                url: "/printWelcomeMail",
                xhrFields: {
                    responseType: "blob"
                },
                data: {idBrand:idBrand},
                error: function(jqXHR, textStatus, errorThrown) {

                    $('body').css("opacity","1");

                    swal({
                        title: "Echec de génération du fichier",
                        text: "",
                        icon: "",
                        buttons: false,
                        dangerMode: true,
                    });

                    console.log(errorThrown);

                },
                success: function(data) {
                    $('body').css("opacity","1");

                    var d = new Date();
                    var m = d.getMonth()+1;
                    if(m<10)
                        m="0"+m;
                    var d = d.getDate();

                    if (navigator.msSaveOrOpenBlob) {
                        navigator.msSaveOrOpenBlob(data,  "CEDETEL COURRIER SOUSCRIPTION."+d+"."+m+".pdf");
                    } else {
                        var a = document.createElement("a");
                        // Add the element to the DOM for firefox compat
                        document.body.appendChild(a);
                        var url = (window.URL || window.webkitURL).createObjectURL(data);
                        a.href = url;
                        a.download = "CEDETEL COURRIER SOUSCRIPTION."+d+"."+m+".pdf";
                        a.click();
                        //a.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
                        window.URL.revokeObjectURL(url);
                    }


                }
            });

        });



        $('.get_adista_file').click(function (e) {

            $('body').css("opacity","0.4");

            $.ajax({
                type: "POST",
                url: "/getAdistaFile",
                xhrFields: {
                    responseType: "blob"
                },
                data: $(this).serialize(),
                error: function(jqXHR, textStatus, errorThrown) {

                    $('body').css("opacity","1");

                    swal({
                        title: "Echec de génération du fichier",
                        text: "",
                        icon: "",
                        buttons: false,
                        dangerMode: true,
                    });

                    console.log(errorThrown);

                },
                success: function(data) {
                    $('body').css("opacity","1");

                    var filename = "fichier_adista_";
                    var today = new Date();

                    filename+= today.getDate()+"-"+(today.getMonth()+1)+"-"+today.getFullYear()+".txt";

                    if (navigator.msSaveOrOpenBlob) {
                        navigator.msSaveOrOpenBlob(data,  filename);
                    } else {
                        var a = document.createElement("a");
                        // Add the element to the DOM for firefox compat
                        document.body.appendChild(a);
                        var url = (window.URL || window.webkitURL).createObjectURL(data);
                        a.href = url;
                        a.download = filename;
                        a.click();
                        //a.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
                        window.URL.revokeObjectURL(url);
                    }
                }
            });


        });


        $('.acq_sms_alert').click(function (){
            let idAlert = $(this).attr("id").split("_")[3];
            let cliName = $('.sms_alert_cli_'+idAlert).text();
            swal({
                title: "Acquitement alerte",
                text: "Vous confirmez acquiter l'alerte usage SMS du client "+cliName+" ? (L'alerte disparaitra de la liste lorsque la page sera rafraichie)",
                icon: "",
                buttons: true,
                dangerMode: false,
            }).then((confirm) => {
                if (confirm) {
                    $('body').css("opacity", "0.4");

                    $.ajax({
                        type: "POST",
                        url: "/acqSmsUsageAlert",
                        data: {alert_id:idAlert},
                        error: function(jqXHR, textStatus, errorThrown) {
                            $('body').css("opacity","1");
                            swal({
                                title: "Echec",
                                text: "",
                                icon: "",
                                buttons: false,
                                dangerMode: true,
                            });
                            console.log(errorThrown);
                        },
                        success: function(data) {
                            $('body').css("opacity","1");
                        }
                    });

                }
            });


        });


        $('#requisition_req').click(function (){
            $('#modal_requisition').modal('show');
        });

        $('#formRequisition').submit(function (e){

            e.preventDefault();

            let filename="reponse.xlsx";

            $.ajax({
                type: "POST",
                url: "/getRequisitionXls",
                data: {msisdn_list: $('#msisdn_list').val()},
                xhrFields: {
                    responseType: "blob"
                },
                error: function(jqXHR, textStatus, errorThrown) {

                    $('body').css("opacity","1");

                    swal({
                        title: "Echec de récupération du fichier",
                        text: "",
                        icon: "",
                        buttons: false,
                        dangerMode: true,
                    });

                    console.log(errorThrown);
                },
                success: function(data) {
                    $('body').css("opacity","1");

                    if (navigator.msSaveOrOpenBlob) {
                        navigator.msSaveOrOpenBlob(data,  filename);
                    } else {
                        var a = document.createElement("a");
                        // Add the element to the DOM for firefox compat
                        document.body.appendChild(a);
                        var url = (window.URL || window.webkitURL).createObjectURL(data);
                        a.href = url;
                        a.download = filename;
                        a.click();
                        //a.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
                        window.URL.revokeObjectURL(url);
                    }
                }
            });
    });

    }

});