$(document).ready(function() {

    getLastConversations();

    /*setInterval(function(){
        getLastConversations();
    },100000);
*/

    $(".msg_new_lnk").on('click',function (e) {
       e.preventDefault();

       $("#modal_send_msg").modal('show');
    });
    
    $(".msg_transfert").on('click', function (e) {
        e.preventDefault();

        $("#message_id").val( $(this).attr("id").split("_")[1]);

        $("#modal_transfert_msg").modal('show');
    });


    $('#transfert_msg_form').on('submit',function (e) {
        e.preventDefault();

        $('body').css("opacity","0.4");

        $("#modal_transfert_msg").modal('hide');

        $.ajax({
            type: "POST",
            url: $(this).attr('action'),
            dataType: 'json',
            data: $(this).serialize(),
            error: function (jqXHR, textStatus, errorThrown) {

                $('body').css("opacity", "1");

                swal({
                    title: "Echec Transfert message",
                    text: "Problème lors de l'envoi du message, contactez votre administrateur",
                    icon: "",
                    buttons: false,
                    dangerMode: true,
                });

                console.log(errorThrown);
            },
            success: function (result) {

                $('body').css("opacity", "1");

                setTimeout(
                    function()
                    {
                        location.reload();
                    }, 1000);

            }
        });

    });


    $('#new_msg_form').on('submit',function (e) {
        e.preventDefault();

        $('body').css("opacity","0.4");

        $("#modal_send_msg").modal('hide');

        $.ajax({
            type: "POST",
            url: $(this).attr('action'),
            dataType: 'json',
            data: $(this).serialize(),
            error: function (jqXHR, textStatus, errorThrown) {

                $('body').css("opacity", "1");

                swal({
                    title: "Echec Envoi message",
                    text: "Problème lors de l'envoi du message, contactez votre administrateur",
                    icon: "",
                    buttons: false,
                    dangerMode: true,
                });

                console.log(errorThrown);
            },
            success: function (result) {

                $('body').css("opacity", "1");

                setTimeout(
                    function()
                    {
                        location.reload();
                    }, 1000);

            }
        });

    });

});


function getLastConversations(){

    $.ajax({
        type: "POST",
        url: "/getUnreadMessages",
        dataType : 'json',
        data: $(this).serialize(),
        error: function(jqXHR, textStatus, errorThrown) {
            console.log(errorThrown.toString());
        },
        success: function(result) {
            console.log(result);

            $(".notification-area .dropdown .dropdown-toggle span").text(result.length);

            var eltToUpd = $('.nofity-list');
            var htmlStr = "";
            $.each(result, function (index, element) {

                htmlStr += "<a href=\"/getConversation/"+element["id"]+"\" class=\"notify-item\">";
                htmlStr += "<div class=\"notify-thumb\">"+element["user_orig"]+"</div>";
                htmlStr += "<div class=\"notify-text\">";
                htmlStr += "<p>"+element["subject"]+"</p>";
                htmlStr += "<span class=\"msg\">"+element["message"]+"</span>";
                htmlStr += "<span>"+element["date"]+"</span>";
                htmlStr += "</div>";
                htmlStr += "</a>";

            });

            if(eltToUpd.html() != htmlStr)
                eltToUpd.html(htmlStr);
        }
    });

}

