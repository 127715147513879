if ($(".page_recherche_prospect").length) {
    $('#cardprospecttable').hide();

    var form = $('#searchProspectForm');
    form.submit( function(event) {
        $('#pagination').empty();
        event.preventDefault();
        $('#cardprospecttable table tbody').empty();
        $('#inputhiddenpage').val(1);
        getResult();
        goToByScroll();
    });

    function goToByScroll() {
        // Remove "link" from the ID
        // Scroll
        $('html, body').animate({
            scrollTop: $("#cardprospecttable").offset().top
        }, 1000)
    }

    $(document).on('click','.page-link',function (e) {
        $('#paginationProspect').empty();
        e.preventDefault();
        var page = $(this).text();
        $('#inputhiddenpage').val(page);
        $('#cardprospecttable table tbody').empty();
        getResult();
    })

    function getResult() {
        $.ajax({
            type: "POST",
            url: "/prospect",
            dataType : "json",
            data: form.serialize(),
            error: function(jqXHR, textStatus, errorThrown) {
                console.log(textStatus);
                console.log(errorThrown);
            },
            success: function(result) {
                var response  = JSON.parse(result);
                //console.log(response);

                if(response["count"] == 0){
                    $('#cardprospecttable').show();
                    $('#cardprospecttable table tbody').append("<tr><td colspan='7'>Aucun résultats</td></tr>");
                }
                else{

                    page = response["count"] / 10;
                    var statusList = response["status_list"];

                    $.each(response, function(index, element) {
                        if (index != "count" && index != "status_list") {

                            var statusHistoryPart = "";
                            for (i = 0; i < statusList.length; i++) {
                                var date = "N/A";
                                for ( j = 0; j < element['status_history'].length; j++) {
                                    if(statusList[i]['id'] == element['status_history'][j]['id']){
                                        date = element['status_history'][j]['date'];
                                    }
                                }
                                statusHistoryPart += "<td>"+date+"</td>";
                            }


                            $('#cardprospecttable').show();
                            $('#cardprospecttable table tbody').append("<tr> "+
                                "<th scope='row'><a href='/prospect-details/"+element['id']+"'>"+element['prospect']+"</a></th>" +
                                "<td>"+element['phone']+"</td>" +
                                "<td>"+element['zip']+"</td>" +
                                "<td>"+element['city']+"</td>" +
                                "<td>"+element['email']+"</td>" +
                                statusHistoryPart +
                                "<td>"+element['status']+"</td>" +
                                "<td>" +
                                "<ul class=\"d-flex justify-content-center\">" +
                                "<li class=\"mr-3\"><a <a href='/edit-prospect/"+element['id']+"' class=\"text-secondary\"><i class=\"fa fa-edit\"></i></a></li>" +
                                "<li><a href='/delete-prospect/"+element['id']+"' class=\"text-danger\"><i class=\"ti-trash\"></i></a></li>" +
                                "</ul>\n" +
                                "</td>" +
                                "</tr>");
                        } else {
                        }
                    });

                    if(page > 1){

                        var current_page = parseInt($('#inputhiddenpage').val());



                        if(current_page != 1){
                            $('#paginationProspect').append("<li class=\"paginate_button page-item previous\"><a href=\"#\" tabindex=\""+(current_page-1)+"\" class=\"page-link\">Précédent</a></li>");
                        }

                        if(current_page > 3){
                            $('#paginationProspect').append("<li class=\"paginate_button page-item disabled\"><a href=\"#\" tabindex=\"0\" class=\"page-link\">...</a></li>");

                            for (var i = current_page-2; i <= current_page+2 && i <= page; i++) {

                                if(i==current_page){
                                    $('#paginationProspect').append("<li class=\"paginate_button page-item active\"><a href=\"#\" tabindex=\""+i+"\" class=\"page-link\">"+i+"</a></li>");
                                }
                                else {
                                    $('#paginationProspect').append("<li class=\"paginate_button page-item\"><a href=\"#\" tabindex=\""+i+"\" class=\"page-link\">"+i+"</a></li>");
                                }
                            }


                            if(page > current_page+2){
                                $('#paginationProspect').append("<li class=\"paginate_button page-item disabled\"><a href=\"#\" tabindex=\"0\" class=\"page-link\">...</a></li>");
                            }

                            if(page > current_page){
                                $('#paginationProspect').append("<li class=\"paginate_button page-item next\"><a href=\"#\" tabindex=\""+(current_page+1)+"\" class=\"page-link\">Suivant</a></li>");
                            }
                        }
                        else {
                            if(current_page == 1){
                                $('#paginationProspect').append("<li class=\"paginate_button page-item active\"><a href=\"#\" tabindex=\"1\" class=\"page-link\">1</a></li>");
                            }
                            else {
                                $('#paginationProspect').append("<li class=\"paginate_button page-item\"><a href=\"#\" tabindex=\"1\" class=\"page-link\">1</a></li>");
                            }

                            if(current_page == 2){
                                $('#paginationProspect').append("<li class=\"paginate_button page-item active\"><a href=\"#\" tabindex=\"2\" class=\"page-link\">2</a></li>");
                            }
                            else if(page > 1){
                                $('#paginationProspect').append("<li class=\"paginate_button page-item\"><a href=\"#\" tabindex=\"2\" class=\"page-link\">2</a></li>");
                            }

                            if(current_page == 3){
                                $('#paginationProspect').append("<li class=\"paginate_button page-item active\"><a href=\"#\" tabindex=\"3\" class=\"page-link\">3</a></li>");
                            }
                            else if(page > 2){
                                $('#paginationProspect').append("<li class=\"paginate_button page-item\"><a href=\"#\" tabindex=\"3\" class=\"page-link\">3</a></li>");
                            }

                            if(current_page == 4){
                                $('#paginationProspect').append("<li class=\"paginate_button page-item active\"><a href=\"#\" tabindex=\"4\" class=\"page-link\">4</a></li>");
                            }
                            else if(page > 3){
                                $('#paginationProspect').append("<li class=\"paginate_button page-item\"><a href=\"#\" tabindex=\"4\" class=\"page-link\">4</a></li>");
                            }

                            if(current_page == 5){
                                $('#paginationProspect').append("<li class=\"paginate_button page-item active\"><a href=\"#\" tabindex=\"5\" class=\"page-link\">5</a></li>");
                            }
                            else if(page > 4){
                                $('#paginationProspect').append("<li class=\"paginate_button page-item\"><a href=\"#\" tabindex=\"5\" class=\"page-link\">5</a></li>");
                            }

                            if(page > 5){
                                $('#paginationProspect').append("<li class=\"paginate_button page-item disabled\"><a href=\"#\" tabindex=\"0\" class=\"page-link\">...</a></li>");
                                $('#paginationProspect').append("<li class=\"paginate_button page-item next\"><a href=\"#\" tabindex=\""+(current_page+1)+"\" class=\"page-link\">Suivant</a></li>");
                            }
                        }

                    }

                }
            }
        });
    }


    if ($(".depot_bt_file").length) {

        $(function () {
            $("#xls_file").change(function () {
                let label = $("label[for='" + $(this).attr('id') + "']");
                if(document.getElementById("xls_file").files[0] != null){
                    let fileName = document.getElementById("xls_file").files[0].name;
                    label.html(fileName);
                }
                else {
                    label.html("Choisir un fichier");
                }

            });
        });

    }

    var swal2 = require("sweetalert2");


    $('#form_import_prospects').submit(function (e) {
        e.preventDefault();

        $('body').css("opacity","0.4");

        var inputFile = document.getElementById('xls_file');
        var file = inputFile.files[0];
        var data = new FormData();
        data.append('xls_file', file, file.name);

        $.ajax({
            type: "POST",
            url: $(this).attr('action'),
            cache: false,
            async: true,
            contentType: false,
            processData: false,
            timeout: 0,
            data: data,
            error: function(jqXHR, textStatus, errorThrown) {

                $('body').css("opacity","1");

                console.log(errorThrown);
            },
            success: function(result) {
                $('body').css("opacity","1");

                if(result.hasOwnProperty("error")){
                    swal2.fire({
                        title: "",
                        html: result["error"],
                        icon: "",
                        showConfirmButton: false,
                        width: "600px",
                        padding: "0",
                        heightAuto: true
                    });
                }
                else {

                    var htmlStr = "<form action='/import_prospects' id='form_conf_imp' method='post'>";

                    htmlStr += "<input type='hidden' name='nb_prospect' value='"+result["prospects"].length+"'>"

                    htmlStr += "<div class='row'>";

                    if(result.hasOwnProperty("call_center")){

                        htmlStr += "<div class='col-lg-6'>";

                        htmlStr += "<div class='form-group'>\n" +
                            "<label for='call_center' class='col-form-label'>Centre d'appel</label>\n"+
                            "<select name='call_center' id='call_center' class='custom-select'>\n";
                        htmlStr += "<option value='-1' selected>Selectionner un centre d'appel</option>\n";

                        $.each(result["call_center"],function (index,element) {
                            htmlStr += "<option value='"+element["id"]+"'>"+element["name"]+"</option>\n";
                        });

                        htmlStr += "</select></div></div>";
                    }

                    htmlStr += "<div class='col-lg-6'>";
                    htmlStr += "<div class='form-group'>\n" +
                        "<label for='brand' class='col-form-label'>Enseigne</label>\n"+
                        "<select name='brand' id='brand' class='custom-select'>\n";
                    htmlStr += "<option value='-1' selected>Selectionner une enseigne</option>\n";

                    $.each(result["brands"],function (index,element) {
                        htmlStr += "<option value='"+element["id"]+"'>"+element["name"]+"</option>\n";
                    });

                    htmlStr += "</select></div></div>";


                    htmlStr += "</div>";

                    $.each(result["prospects"],function (index,element) {
                        htmlStr += "<input type='hidden' name='status_"+index+"' value='"+element["status"]+"'>"

                        var backgroundCol = "background-color:#FFFFFF";
                        if(index%2 == 0)
                            backgroundCol = "background-color:#F5F5F5";

                        htmlStr += "<div class='row' style='"+backgroundCol+"'>";

                        var readonly = "readonly";

                        htmlStr += "<div class='col-lg-2'>";
                        htmlStr += "<div class='form-group'>\n" +
                            "<label for='call_date_"+index+"' class='col-form-label'>Date</label>\n" +
                            "<input class='form-control' type='text' "+readonly+" value='"+element["call_date"]+"' name='call_date_"+index+"' id='call_date_"+index+"' autocomplete='off'>\n" +
                            "</div>";
                        htmlStr += "</div>";

                        htmlStr += "<div class='col-lg-2'>";
                        htmlStr += "<div class='form-group'>\n" +
                            "<label for='teleoperator_"+index+"' class='col-form-label'>Téléopérateur</label>\n" +
                            "<input class='form-control' type='text' "+readonly+" value='"+element["teleoperator"]+"' name='teleoperator_"+index+"' id='teleoperator_"+index+"' autocomplete='off'>\n" +
                            "</div>";
                        htmlStr += "</div>";

                        htmlStr += "<div class='col-lg-2'>";
                        htmlStr += "<div class='form-group'>\n" +
                            "<label for='phone_number_"+index+"' class='col-form-label'>Numéro téléphone</label>\n" +
                            "<input class='form-control' type='text' "+readonly+" value='"+element["phone_number"]+"' name='phone_number_"+index+"' id='phone_number_"+index+"' autocomplete='off'>\n" +
                            "</div>";
                        htmlStr += "</div>";

                        readonly = "";
                        if(element['status'] == 'already_in_db' || element['status'] == 'not_in_bloctel_list' || element['status'] == 'bloctel_too_old' || element['status'] == 'already_on_prospect')
                            readonly = "readonly";

                        htmlStr += "<div class='col-lg-2'>";
                        htmlStr += "<div class='form-group'>\n" +
                            "<label for='first_name_"+index+"' class='col-form-label'>Prénom</label>\n" +
                            "<input class='form-control' type='text' "+readonly+" value='"+element["first_name"]+"' name='first_name_"+index+"' id='first_name_"+index+"' autocomplete='off'>\n" +
                            "</div>";
                        htmlStr += "</div>";

                        htmlStr += "<div class='col-lg-2'>";
                        htmlStr += "<div class='form-group'>\n" +
                            "<label for='last_name_"+index+"' class='col-form-label'>Nom</label>\n" +
                            "<input class='form-control' type='text' "+readonly+" value='"+element["last_name"]+"' name='last_name_"+index+"' id='last_name_"+index+"' autocomplete='off'>\n" +
                            "</div>";
                        htmlStr += "</div>";

                        htmlStr += "<div class='col-lg-2'>";
                        htmlStr += "<div class='form-group'>\n" +
                            "<label for='comment_"+index+"' class='col-form-label'>Commentaire</label>\n" +
                            "<input class='form-control' type='text' "+readonly+" value='"+element["comment"]+"' name='comment_"+index+"' id='comment_"+index+"' autocomplete='off'>\n" +
                            "</div>";
                        htmlStr += "</div>";


                        htmlStr += "</div><div class='row' style='"+backgroundCol+"'>";

                        htmlStr += "<div class='col-lg-4'>";
                        htmlStr += "<div class='form-group'>\n" +
                            "<label for='address_"+index+"' class='col-form-label'>Adresse</label>\n" +
                            "<input class='form-control' type='text' "+readonly+" value='"+element["address"]+"' name='address_"+index+"' id='address_"+index+"' autocomplete='off'>\n" +
                            "</div>";
                        htmlStr += "</div>";

                        htmlStr += "<div class='col-lg-2'>";
                        htmlStr += "<div class='form-group'>\n" +
                            "<label for='postal_code_"+index+"' class='col-form-label'>Code postal</label>\n" +
                            "<input class='form-control' type='text' readonly value='"+element["postal_code"]+"' name='postal_code_"+index+"' id='postal_code_"+index+"' autocomplete='off'>\n" +
                            "</div>";
                        htmlStr += "</div>";


                        if(element['status'] == 'ko'){

                            htmlStr += "<div class='col-lg-3'>";
                            htmlStr += "<div class='form-group'>\n" +
                                "<label for='city_"+index+"' class='col-form-label'>Ville</label>\n"+
                                "<select name='city_"+index+"' id='city_"+index+"' class='custom-select'>\n";
                            $.each(element['cities'],function (index2,element2) {
                                    htmlStr += "<option value='"+element2+"'>"+element2+"</option>\n";
                                });
                            htmlStr += "</select></div>";
                            htmlStr += "</div>";


                            htmlStr += "<div class='col-lg-3'>";
                            htmlStr += "<div class='form-group'>\n" +
                            "<label for='status' class='col-form-label'>Statut</label>\n";
                            htmlStr += "L'api geo.api.gouv.fr ne trouve pas la ville indiquée au code postal indiqué veuillez choisir une ville dans la liste";
                            htmlStr += "</div></div>";

                        }
                        else {
                            htmlStr += "<div class='col-lg-3'>";
                            htmlStr += "<div class='form-group'>\n" +
                                "<label for='city_"+index+"' class='col-form-label'>Ville</label>\n" +
                                "<input class='form-control' type='text' readonly value='"+element["city"]+"' name='city_"+index+"' id='city_"+index+"' autocomplete='off'>\n" +
                                "</div>";
                            htmlStr += "</div>";

                            if(element['status'] == 'already_in_db'){
                                htmlStr += "<div class='col-lg-3'>";
                                htmlStr += "<div class='form-group'>\n" +
                                    "<label for='status' class='col-form-label'>Statut</label>\n";
                                htmlStr += "Ce prospect ne sera pas importé car il existe déja en base de données";
                                htmlStr += "</div></div>";
                            }
                            else if(element['status'] == 'not_in_bloctel_list') {
                                htmlStr += "<div class='col-lg-3'>";
                                htmlStr += "<div class='form-group'>\n" +
                                    "<label for='status' class='col-form-label'>Statut</label>\n";
                                htmlStr += "Le N° de téléphone saisi ne fait pas parti des numéros validés par bloctel";
                                htmlStr += "</div></div>";
                            }
                            else if(element['status'] == 'bloctel_too_old'){
                                htmlStr += "<div class='col-lg-3'>";
                                htmlStr += "<div class='form-group'>\n" +
                                    "<label for='status' class='col-form-label'>Statut</label>\n";
                                htmlStr += "Le N° de téléphone saisi fait parti des numéros validés par bloctel, mais la date de validation est supérieure à 30 jours";
                                htmlStr += "</div></div>";
                            }
                            else if(element['status'] == 'already_on_prospect'){
                                htmlStr += "<div class='col-lg-3'>";
                                htmlStr += "<div class='form-group'>\n" +
                                    "<label for='status' class='col-form-label'>Statut</label>\n";
                                htmlStr += "Le N° de téléphone saisi est déja présent en base de données";
                                htmlStr += "</div></div>";
                            }
                            else {
                                htmlStr += "<div class='col-lg-3'>";
                                htmlStr += "<div class='form-group'>\n" +
                                    "<label for='status' class='col-form-label'>Statut</label>\n";
                                htmlStr += "Ok";
                                htmlStr += "</div></div>";
                            }

                        }

                        htmlStr += "</div>";

                    });

                    htmlStr +="<div class='form-group'>\n" +
                        "<button type='submit' class='btn btn-primary mt-4 pr-4 pl-4'>Importer</button>\n" +
                        "</div>";
                    htmlStr +="</form>";

                    $('#modal_confirm_imp_body').html(htmlStr);
                    $('#modal_confirm_imp').modal('show');

                }
            }
        });

    });

    $(document).on('submit','#form_conf_imp',function (e) {

        if($('#call_center').length){

            if($( "#call_center option:selected" ).val() == "-1"){
                swal2.fire({
                    title: "",
                    html: "Vous devez choisir un centre d'appel",
                    icon: "",
                    showConfirmButton: false,
                    width: "600px",
                    padding: "0",
                    heightAuto: true
                });
                return false;
            }

        }

        if($( "#brand option:selected" ).val() == "-1"){
            swal2.fire({
                title: "",
                html: "Vous devez choisir une enseigne",
                icon: "",
                showConfirmButton: false,
                width: "600px",
                padding: "0",
                heightAuto: true
            });
            return false;
        }

    });

}



