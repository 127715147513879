

if ($(".page_recherche_client").length) {


    $('#cardtable').hide();

    var form = $('#searchForm');
    form.submit( function(event) {
        $('#pagination').empty();
        event.preventDefault();
        $('#cardtable table tbody').empty();
        $('#inputhiddenpage').val(1);
        getResult();
        goToByScroll();
    });

    function goToByScroll() {
        // Remove "link" from the ID
        // Scroll
        $('html, body').animate({
            scrollTop: $("#cardtable").offset().top
        }, 1000)
    }

    $(document).on('click','.page-link',function (e) {
        $('#pagination').empty();
        e.preventDefault();
        var page = $(this).attr("tabindex");
        $('#inputhiddenpage').val(page);
        $('#cardtable table tbody').empty();
        getResult();
    })

    function getResult() {
        $.ajax({
            type: "POST",
            url: "/recherche",
            dataType : 'json',
            data: form.serialize(),
            error: function(jqXHR, textStatus, errorThrown) {
                console.log(errorThrown);
            },
            success: function(result) {
                var response  = JSON.parse(result);
                if(response["count"] == 0){
                    $('#cardtable').show();
                    $('#cardtable table tbody').append("<tr><td colspan='7'>Aucun résultats</td></tr>");
                }
                else {
                    page = response["count"] / 10;
                    $.each(response, function(index, element) {
                        if (index != "count") {
                            $('#cardtable').show();
                            $('#cardtable table tbody').append("<tr> "+
                                "<td scope='row'><a href='/client-details/"+element['id_client']+"' target='_parent'>"+element['client']+"</a></td>" +
                                "<td>"+element['phone']+"</td>" +
                                "<td>"+element['brand']+"</td>" +
                                "<td>"+element['city']+"("+element['zip']+")</td>" +
                                "<td>"+element['email']+"</td>" +
                                "<td>"+element['status']+"</td>" +
                                "<td>" +
                                "<ul class=\"d-flex justify-content-center\">" +
                                "<li class=\"mr-3\"><a href='/edit-client/"+element['id_client']+"' class=\"text-secondary\"><i class=\"fa fa-edit\"></i></a></li>" +
                                "<li><a href='/delete-client/"+element['id_client']+"' class=\"text-danger\"><i class=\"ti-trash\"></i></a></li>" +
                                "</ul>\n" +
                                "</td>" +
                                "</tr>");
                        } else {
                        }
                    });

                    if(page > 1){


                        var current_page = parseInt($('#inputhiddenpage').val());

                        if(current_page != 1){
                            $('#pagination').append("<li class=\"paginate_button page-item previous\"><a href=\"#\" tabindex=\""+(current_page-1)+"\" class=\"page-link\">Précédent</a></li>");
                        }

                        if(current_page > 3){
                            $('#pagination').append("<li class=\"paginate_button page-item disabled\"><a href=\"#\" tabindex=\"0\" class=\"page-link\">...</a></li>");

                            for (var i = current_page-2; i <= current_page+2 && i <= page; i++) {

                                if(i==current_page){
                                    $('#pagination').append("<li class=\"paginate_button page-item active\"><a href=\"#\" tabindex=\""+i+"\" class=\"page-link\">"+i+"</a></li>");
                                }
                                else {
                                    $('#pagination').append("<li class=\"paginate_button page-item\"><a href=\"#\" tabindex=\""+i+"\" class=\"page-link\">"+i+"</a></li>");
                                }
                            }


                            if(page > current_page+2){
                                $('#pagination').append("<li class=\"paginate_button page-item disabled\"><a href=\"#\" tabindex=\"0\" class=\"page-link\">...</a></li>");
                            }

                            if(page > current_page){
                                $('#pagination').append("<li class=\"paginate_button page-item next\"><a href=\"#\" tabindex=\""+(current_page+1)+"\" class=\"page-link\">Suivant</a></li>");
                            }
                        }
                        else {
                            if(current_page == 1){
                                $('#pagination').append("<li class=\"paginate_button page-item active\"><a href=\"#\" tabindex=\"1\" class=\"page-link\">1</a></li>");
                            }
                            else {
                                $('#pagination').append("<li class=\"paginate_button page-item\"><a href=\"#\" tabindex=\"1\" class=\"page-link\">1</a></li>");
                            }

                            if(current_page == 2){
                                $('#pagination').append("<li class=\"paginate_button page-item active\"><a href=\"#\" tabindex=\"2\" class=\"page-link\">2</a></li>");
                            }
                            else if(page > 1){
                                $('#pagination').append("<li class=\"paginate_button page-item\"><a href=\"#\" tabindex=\"2\" class=\"page-link\">2</a></li>");
                            }

                            if(current_page == 3){
                                $('#pagination').append("<li class=\"paginate_button page-item active\"><a href=\"#\" tabindex=\"3\" class=\"page-link\">3</a></li>");
                            }
                            else if(page > 2){
                                $('#pagination').append("<li class=\"paginate_button page-item\"><a href=\"#\" tabindex=\"3\" class=\"page-link\">3</a></li>");
                            }

                            if(current_page == 4){
                                $('#pagination').append("<li class=\"paginate_button page-item active\"><a href=\"#\" tabindex=\"4\" class=\"page-link\">4</a></li>");
                            }
                            else if(page > 3){
                                $('#pagination').append("<li class=\"paginate_button page-item\"><a href=\"#\" tabindex=\"4\" class=\"page-link\">4</a></li>");
                            }

                            if(current_page == 5){
                                $('#pagination').append("<li class=\"paginate_button page-item active\"><a href=\"#\" tabindex=\"5\" class=\"page-link\">5</a></li>");
                            }
                            else if(page > 4){
                                $('#pagination').append("<li class=\"paginate_button page-item\"><a href=\"#\" tabindex=\"5\" class=\"page-link\">5</a></li>");
                            }

                            if(page > 5){
                                $('#pagination').append("<li class=\"paginate_button page-item disabled\"><a href=\"#\" tabindex=\"0\" class=\"page-link\">...</a></li>");
                                $('#pagination').append("<li class=\"paginate_button page-item next\"><a href=\"#\" tabindex=\""+(current_page+1)+"\" class=\"page-link\">Suivant</a></li>");
                            }
                        }


                    }

                }

            }
        });
    }


}


