$(document).ready(function() {

    if ($('.error_api_page').length) {

        let locale = {
            format: "DD/MM/YYYY",
            separator: " - ",
            applyLabel: "Appliquer",
            cancelLabel: "Annuler",
            fromLabel: "De",
            toLabel: "À",
            customRangeLabel: "Personnalisé",
            weekLabel: "S",
            daysOfWeek: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
            monthNames: [
                "Janvier",
                "Février",
                "Mars",
                "Avril",
                "Mai",
                "Juin",
                "Juillet",
                "Août",
                "Septembre",
                "Octobre",
                "Novembre",
                "Décembre"
            ],
            firstDay: 1
        };

        let option = {
            locale: locale,
            showDropdowns: true
        };

        $('input[name="dates_errors"]').daterangepicker(option);


    }
});