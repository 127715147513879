

$(document).ready(function() {

    if($('.analyse_page').length) {

        let locale = {
            format: "DD/MM/YYYY",
            separator: " - ",
            applyLabel: "Appliquer",
            cancelLabel: "Annuler",
            fromLabel: "De",
            toLabel: "À",
            customRangeLabel: "Personnalisé",
            weekLabel: "S",
            daysOfWeek: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
            monthNames: [
                "Janvier",
                "Février",
                "Mars",
                "Avril",
                "Mai",
                "Juin",
                "Juillet",
                "Août",
                "Septembre",
                "Octobre",
                "Novembre",
                "Décembre"
            ],
            firstDay: 1
        };

        let option = {
            locale: locale,
            showDropdowns: true
        };

        $('input[name="dates_analyse"]').daterangepicker(option);


        $('#ccenter').change(function () {

            var selectedCallcenter = $(this).children("option:selected").val();

            $( ".brandcb" ).each(function(index) {
                $(this).hide();
            });

            $(".brandcblst").each(function (index) {
                $(this).prop('checked', false);
            })

            if(selectedCallcenter != -1){
                $('#brand_cb_'+selectedCallcenter).show();
            }
        });


        $("#search_analyse").submit(function (e) {
            e.preventDefault();
            $('#analyse_chart_row').hide();

            $('body').css("opacity","0.4");

            $.ajax({
                type: "POST",
                url: $(this).attr('action'),
                dataType : 'json',
                data: $(this).serialize(),
                error: function(jqXHR, textStatus, errorThrown) {
                    console.log(errorThrown);
                    $('body').css("opacity","1");
                    $('#tab_stat_res').show();
                    $('#stat_analyse_tab  table tbody').append("<tr> " +
                        "<th scope='row' colspan='7'>Erreur : "+errorThrown+"</th>" +
                        "</tr>");
                },
                success: function(result) {

                    $('body').css("opacity","1");

                    $('#tab_stat_res').show();
                    var response = result;
                    var tableTofill = $('#stat_analyse_tab  table tbody');
                    tableTofill.empty();
                    if(response.hasOwnProperty("error")){
                        tableTofill.append("<tr class=\"simplus_orange_bg\"> " +
                            "<th scope='row' colspan='7'>Erreur : "+response['error']+"</th>" +
                            "</tr>");
                    }
                    else {

                        tableTofill.append("<tr> ");
                        var idx=0;
                        var nbTotal=-1;
                        $.each(response['status_list'], function (index, element) {
                            if(idx == 0){
                                nbTotal = element.length;
                                idx++;
                                tableTofill.append( "<td>"+element.length+"</td>");
                            }
                            else {
                                var pct = -1;
                                if(nbTotal == 0){
                                    pct = 0;
                                }
                                else {
                                    pct = (element.length/nbTotal)*100;
                                }
                                tableTofill.append( "<td>"+element.length+" ("+pct.toFixed(2)+"%)</td>");
                            }

                        });
                        tableTofill.append("</tr> ");

                        var dates_analyse = $("input[name='dates_analyse']").val();
                        var select_ccenter = $("select[name='ccenter']");
                        var ccenter = select_ccenter.children("option:selected").val();

                        var brandlst = "";
                        $('.brandcblst').each(function() {

                            if($(this).is(":checked")){
                                if(brandlst != "")
                                    brandlst = brandlst + ",";
                                brandlst = brandlst + $(this).attr("name").split("_")[2];
                            }



                        });


                        $("#link_details").attr("href","/details_accords?dates_analyse="+dates_analyse+"&ccenter="+ccenter+"&brandlst="+brandlst);
                        $("#link_details").text("Voir details");

                        var colors = ['#F0B41A','#0839F0','#3BF030','#F03DE3','#B777F0','#79F0EE','#F0582C','#A2B6F0','#CCCCCC','#2476CC','#F0EA10','#F09C3A','#89F041','#000000'];
                        var array_X_axis =[];
                        var array_series = [];
                        Object.keys(response['curve_data']).forEach(function(k){
                            //console.log(k + ' - ' + response['curve_data'][k]);
                            array_X_axis.push(k);

                            var i=0;
                            Object.keys(response['curve_data'][k]).forEach(function(l){
                                if(array_X_axis.length <= 1){
                                    array_series[i] = {
                                        "values": [ ],
                                        "backgroundColor": colors[i],
                                        "text": l.split('_')[1]
                                    };
                                }

                                array_series[i]["values"].push(response['curve_data'][k][l]);

                                i++;
                            });

                        });

                        //console.log(array_X_axis);
                        //console.log(array_series);

                        $('#analyse_chart_row').show();

                        var myConfig = {
                            "type": "bar",

                            "scale-x": { //X-Axis
                                "labels": array_X_axis,
                                "label": {
                                    "font-size": 14,
                                    "offset-x": 0,
                                },
                                "item": { //Scale Items (scale values or labels)
                                    "font-size": 10,
                                },
                                "guide": { //Guides
                                    "visible": false,
                                    "line-style": "solid", //"solid", "dotted", "dashed", "dashdot"
                                    "alpha": 1
                                }
                            },
                            "plot": { "barWidth":10 },
                            "series": array_series,
                            "legend": {
                                "align": 'right',
                                "verticalAlign": 'top',
                                "backgroundColor": 'none',
                                "borderWidth": 1

                            },
                        };


                        zingchart.render({
                            id: 'analyse-shart',
                            data: myConfig,
                            height: "100%",
                            width: "100%"
                        });
                        $('#analyse-shart').css("overflow","visible");



                    }
                }
            });
        });





    }


});


