
if($('.gest_campagne').length) {
    $(document).ready(function (e) {


        $(document).on("change paste keyup","#search_input", function () {
            var strSearch = $(this).val().toLowerCase();

            if(strSearch.length > 2){
                $('.table tbody tr').each(function (index) {

                    var found = false;
                    $(this).find("td").each(function (index) {
                        if($(this).text().toLowerCase().indexOf(strSearch) >= 0){
                            found = true;
                            return false;
                        }
                    });

                    if(!found){
                        $(this).hide();
                    }
                });
            }
            else {
                $('.table tbody tr').show();
            }
        });


        $(document).on('click','.validateFullList',function (e) {
            var idStatus = $(this).attr('id').split("_")[1];
            var idBrand = $('#brandId').val();

            $.ajax({
                method: "post",
                url: "/validate_full_list",
                data: {idStatus:idStatus, idBrand:idBrand},
                dataType: 'json',
                success: function(data) {
                    //console.log(data);
                    //var response = JSON.parse(data);
                    if(data['error']){
                        swal({
                            title: "Problème lors du changement de statut",
                            text: data['error'],
                            icon: "error",
                            buttons: false,
                            dangerMode: true,
                        });
                    }
                    else{
                        swal({
                            title: "Changement de statut effectué avec succès",
                            text: "",
                            icon: "success",
                            buttons: false,
                            dangerMode: false,
                        });
                        setTimeout(
                            function()
                            {
                                location.reload();
                            }, 3000);
                    }
                }
            });
        });


        $(document).on('submit','.upd_address',function (e) {

            e.preventDefault();

            var form = $(this);
            $.ajax({
                type: "POST",
                url: $(this).attr('action'),
                dataType : 'json',
                data: $(this).serialize(),
                error: function(jqXHR, textStatus, errorThrown) {
                    swal({
                        title: "Problème lors de la mise à jour de l'adresse",
                        text: textStatus,
                        icon: "error",
                        buttons: false,
                        dangerMode: true,
                    });
                },
                success: function(result) {
                    //console.log(result);
                    var response  = result;
                    if(response.hasOwnProperty("error")){
                        swal({
                            title: "Problème lors de la mise à jour de l'adresse",
                            text: response["error"],
                            icon: "error",
                            buttons: false,
                            dangerMode: true,
                        });
                    }
                    else {
                        form.hide();
                    }
                }
            });
        });



        $(document).on('click','.listfullexp',function (e) {
            var idStatus = $(this).attr('id').split("_")[1];
            var idBrand = $('#brandId').val();
            var brandName = $('#brandName').val();


            $.ajax({
                method: "post",
                url: "/detect_address_anomaly",
                data: {idStatus: idStatus, idBrand: idBrand},
                success: function(response) {
                    //var response = JSON.parse(data);
                    if (response.length > 0) {
                        var strForms="";
                        $.each(response, function (index, element) {

                            strForms +='<form action="/update_prospect_postal_info" method="post" class="upd_address">';
                            strForms += '<div class="row">';
                            strForms += '<div class="col-lg-12 red_color margin-center">';
                            strForms += element['ANOMALY'];
                            strForms += '</div>';
                            strForms += '</div>';
                            strForms += '<div class="row">';
                            strForms +='<input type="hidden" name="id_prospect" value="'+element['ID']+'"/>';
                            strForms += '<div class="col-lg-6">';
                            strForms += '<div class="form-group">';
                            strForms += '<label for="firstname" class="col-form-label">Prénom</label>';
                            strForms +='<input class="form-control" type="text" name="firstname" id="firstname" value="'+element['FNAME']+'"/>';
                            strForms += '</div>';
                            strForms += '</div>';
                            strForms += '<div class="col-lg-6">';
                            strForms += '<div class="form-group">';
                            strForms += '<label for="lastname" class="col-form-label">Nom</label>';
                            strForms +='<input class="form-control" type="text" name="lastname" id="lastname" value="'+element['LNAME']+'"/>';
                            strForms += '</div>';
                            strForms += '</div>';
                            strForms += '</div>';
                            strForms += '<div class="row">';
                            strForms += '<div class="col-lg-12">';
                            strForms += '<div class="form-group">';
                            strForms += '<label for="address" class="col-form-label">Adresse</label>';
                            strForms +='<input class="form-control" type="text" name="address" id="address" value="'+element['ADRESSE']+'"/>';
                            strForms += '</div>';
                            strForms += '</div>';
                            strForms += '</div>';
                            strForms += '<div class="row">';
                            strForms += '<div class="col-lg-12">';
                            strForms += '<div class="form-group">';
                            strForms += '<label for="address" class="col-form-label">Complément d\'adresse</label>';
                            strForms +='<input class="form-control" type="text" name="address_comp" id="address_comp" value="'+element['ADRESSE1']+'"/>';
                            strForms += '</div>';
                            strForms += '</div>';
                            strForms += '</div>';
                            strForms += '<div class="row">';
                            strForms += '<div class="col-lg-3">';
                            strForms += '<div class="form-group">';
                            strForms += '<label for="zipcode" class="col-form-label">Code postal</label>';
                            strForms +='<input class="form-control" type="text" name="zipcode" id="zipcode" value="'+element['CP']+'"/>';
                            strForms += '</div>';
                            strForms += '</div>';
                            strForms += '<div class="col-lg-6">';
                            strForms += '<div class="form-group">';
                            strForms += '<label for="city" class="col-form-label">Ville</label>';
                            strForms +='<input class="form-control" type="text" name="city" id="city" value="'+element['VILLE']+'"/>';
                            strForms += '</div>';
                            strForms += '</div>';
                            strForms += '<div class="col-lg-3 mt-45-px">';
                            strForms += '<a href="http://www.pagesjaunes.fr/pagesblanches/recherche?quoiqui='+element['NOM']+'&ou='+element['VILLE']+'&proximite=0" target="_blank">Voir</a>';
                            strForms += '</div>';
                            strForms += '</div>';
                            strForms += '<div class="row margin-center">';
                            strForms += '<div class="col-lg-12">';
                            strForms += '<button type="submit" class="btn btn-primary mt-4 pr-4 pl-4">Enregistrer</button>';
                            strForms += '</div>';
                            strForms += '</div>';
                            strForms += '</form>';

                        });


                        $("#modal_address_anomaly_body").html(strForms);
                        $("#modal_address_anomaly").modal("show");
                    }
                    else {
                        console.log("Aucune anomalie");

                        $.ajax({
                            method: "post",
                            url: "/export_prospect_list",
                            data: {idStatus:idStatus, idBrand:idBrand},
                            xhrFields: {
                                responseType: "blob"
                            },
                            success: function(data) {

                                var filename = "fichier_quotidien_fleximail_du_";
                                var today = new Date();

                                filename+= today.getDate()+"-"+(today.getMonth()+1)+"-"+today.getFullYear()+"_";
                                filename+= brandName;
                                filename+= "_a_envoyer_le_";
                                filename+= today.getDate()+"-"+(today.getMonth()+1)+"-"+today.getFullYear()+".txt";


                                if (navigator.msSaveOrOpenBlob) {
                                    navigator.msSaveOrOpenBlob(data,   filename);
                                } else {
                                    var a = document.createElement("a");
                                    // Add the element to the DOM for firefox compat
                                    document.body.appendChild(a);
                                    var url = (window.URL || window.webkitURL).createObjectURL(data);
                                    a.href = url;
                                    a.download =  filename;
                                    a.click();
                                    //a.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
                                    window.URL.revokeObjectURL(url);
                                }

                            }
                        });


                    }

                }

            });

        });





        $(document).on('click','.listfullexpFtp',function (e) {
            var idStatus = $(this).attr('id').split("_")[1];
            var idBrand = $('#brandId').val();
            var brandName = $('#brandName').val();

            $.ajax({
                method: "post",
                url: "/detect_address_anomaly",
                data: {idStatus: idStatus, idBrand: idBrand},
                success: function(response) {
                    //var response = JSON.parse(data);
                    if (response.length > 0) {
                        var strForms="";
                        $.each(response, function (index, element) {

                            strForms +='<form action="/update_prospect_postal_info" method="post" class="upd_address">';
                            strForms += '<div class="row">';
                            strForms += '<div class="col-lg-12 red_color margin-center">';
                            strForms += element['ANOMALY'];
                            strForms += '</div>';
                            strForms += '</div>';
                            strForms += '<div class="row">';
                            strForms +='<input type="hidden" name="id_prospect" value="'+element['ID']+'"/>';
                            strForms += '<div class="col-lg-6">';
                            strForms += '<div class="form-group">';
                            strForms += '<label for="firstname" class="col-form-label">Prénom</label>';
                            strForms +='<input class="form-control" type="text" name="firstname" id="firstname" value="'+element['FNAME']+'"/>';
                            strForms += '</div>';
                            strForms += '</div>';
                            strForms += '<div class="col-lg-6">';
                            strForms += '<div class="form-group">';
                            strForms += '<label for="lastname" class="col-form-label">Nom</label>';
                            strForms +='<input class="form-control" type="text" name="lastname" id="lastname" value="'+element['LNAME']+'"/>';
                            strForms += '</div>';
                            strForms += '</div>';
                            strForms += '</div>';
                            strForms += '<div class="row">';
                            strForms += '<div class="col-lg-12">';
                            strForms += '<div class="form-group">';
                            strForms += '<label for="address" class="col-form-label">Adresse</label>';
                            strForms +='<input class="form-control" type="text" name="address" id="address" value="'+element['ADRESSE']+'"/>';
                            strForms += '</div>';
                            strForms += '</div>';
                            strForms += '</div>';
                            strForms += '<div class="row">';
                            strForms += '<div class="col-lg-12">';
                            strForms += '<div class="form-group">';
                            strForms += '<label for="address" class="col-form-label">Complément d\'adresse</label>';
                            strForms +='<input class="form-control" type="text" name="address_comp" id="address_comp" value="'+element['ADRESSE1']+'"/>';
                            strForms += '</div>';
                            strForms += '</div>';
                            strForms += '</div>';
                            strForms += '<div class="row">';
                            strForms += '<div class="col-lg-3">';
                            strForms += '<div class="form-group">';
                            strForms += '<label for="zipcode" class="col-form-label">Code postal</label>';
                            strForms +='<input class="form-control" type="text" name="zipcode" id="zipcode" value="'+element['CP']+'"/>';
                            strForms += '</div>';
                            strForms += '</div>';
                            strForms += '<div class="col-lg-6">';
                            strForms += '<div class="form-group">';
                            strForms += '<label for="city" class="col-form-label">Ville</label>';
                            strForms +='<input class="form-control" type="text" name="city" id="city" value="'+element['VILLE']+'"/>';
                            strForms += '</div>';
                            strForms += '</div>';
                            strForms += '<div class="col-lg-3 mt-45-px">';
                            strForms += '<a href="http://www.pagesjaunes.fr/pagesblanches/recherche?quoiqui='+element['NOM']+'&ou='+element['VILLE']+'&proximite=0" target="_blank">Voir</a>';
                            strForms += '</div>';
                            strForms += '</div>';
                            strForms += '<div class="row margin-center">';
                            strForms += '<div class="col-lg-12">';
                            strForms += '<button type="submit" class="btn btn-primary mt-4 pr-4 pl-4">Enregistrer</button>';
                            strForms += '</div>';
                            strForms += '</div>';
                            strForms += '</form>';

                        });


                        $("#modal_address_anomaly_body").html(strForms);
                        $("#modal_address_anomaly").modal("show");
                    }
                    else {

                        $('body').css("opacity","0.5");

                        console.log("Aucune anomalie");

                        $.ajax({
                            method: "post",
                            url: "/send_prospect_list_to_ftp",
                            data: {idStatus:idStatus, idBrand:idBrand},
                            dataType : 'json',
                            success: function(data) {
                                $('body').css("opacity","1");
                                var response  = data;
                                if(response.hasOwnProperty("error")){
                                    swal({
                                        title: "Echec lors de l'envoi vers le FTP fleximail",
                                        text: response['error'],
                                        icon: "",
                                        buttons: false,
                                        dangerMode: true,
                                    });
                                }
                                else {
                                    swal({
                                        title: "Fichier envoyé",
                                        text: response['success'],
                                        icon: "",
                                        buttons: false,
                                        dangerMode: false,
                                    });
                                    setTimeout(
                                        function()
                                        {
                                            location.reload();
                                        }, 3000);
                                }

                            }
                        });


                    }

                }

            });

        });







        //cache tout les bouttons full list quand doc ready
        $('.listday').each(function (index) {
            $(this).hide();
            if (index === 0) {
                var idStatus = $(this).attr('id').split("_")[1];
                var isFinal = $(this).attr('id').split(/\_(?=[^\_]+$)/);
                fillTable(idStatus, "day", $('#card_' + idStatus + "_" + isFinal[1] + ' table tbody'), isFinal[1]);
            }
        });

        //action click btn list jour
        $('.listday').click(function () {
            var idStatus = $(this).attr('id').split("_")[1];
            var isFinal = $(this).attr('id').split(/\_(?=[^\_]+$)/);

            fillTable(idStatus, "day", $('#card_' + idStatus + "_" + isFinal[1] + ' table tbody'), isFinal[1]);

            $(this).hide();
            $('#btnlistfull_' + idStatus + "_" + isFinal[1]).show();
        });

        //action click btn list full
        $('.listfull').click(function () {
            var idStatus = $(this).attr('id').split("_")[1];
            var isFinal = $(this).attr('id').split(/\_(?=[^\_]+$)/);

            fillTable(idStatus, "day", $('#card_' + idStatus + "_" + isFinal[1] + ' table tbody'), isFinal[1]);

            $(this).hide();
            $('#btnday_' + idStatus + "_" + isFinal[1]).show();
        });


        //action click tab
        $('.nav-link ').click(function () {

            var idStatus = $(this).attr('id').split("_")[1];
            var isFinal = $(this).attr('id').split(/\_(?=[^\_]+$)/);

            $('#card_' + idStatus).show();

            $('#btnlistfull_' + idStatus + "_" + isFinal[1]).show();
            $('#btnday_' + idStatus + "_" + isFinal[1]).hide();

            var card = $('#card_' + idStatus + "_" + isFinal[1] + ' table tbody');
            fillTable(idStatus, "day", card, isFinal[1]);
        });

        $(document).on('click', '.conversion', function (e) {

            e.preventDefault();

            swal({
                title: "Etes vous sur de vouloir convertir le prospect en client ?",
                text: "",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then((confirm) => {
                if (confirm) {
                    var idProspect = $(this).attr("id").split("_")[1];

                    $(this).parents('form').attr("action", "/conversion/" + idProspect);
                    $(this).parents('form').submit();
                }
            });
        });

        $(document).on('click', '.prise', function (e) {
            e.preventDefault();

            swal({
                title: "Etes vous sur de vouloir prendre en charge le prospect ?",
                text: "",
                icon: "",
                buttons: true,
                dangerMode: true,
            })
            .then((confirm) => {
                if (confirm) {
                    var idProspect = $(this).attr("id").split("_")[1];
                    $(this).parents('form').attr("action", "/edit-prospect/"+idProspect);
                    $(this).parents('form').submit();
                }
            });
        });

        function fillTable(type, reqType, card, isFinal) {
            card.empty();
            card.show();

            let idCamp = $('#brandId').val();

            return $.ajax({
                type: "POST",
                url: "/campagne/" + idCamp,
                dataType: 'json',
                data: {type: type, reqType: reqType},
                async: true,
                error: function (error, text) {
                    console.log(error);
                    console.log(text);
                },
                success: function (result) {
                    var response = JSON.parse(result);

                    var prosp_lb = "prospect";
                    if(response.length>1){
                        prosp_lb += "s";
                    }

                    $('#tab_title_'+type+' .prospect_res_count').text("("+response.length+" "+prosp_lb+")");

                    if (response.length > 0) {
                        $.each(response, function (index, element) {

                            var dateObj = element['date_status'].date;
                            var date = new Date(dateObj);
                            var last = date.toISOString().slice(0, 10);
                            var action = "";
                            if (reqType == "day" && isFinal == 1) {
                                action =
                                    "<form method='post'>" +
                                    "<input type='hidden' class='idProspect' name='idProspect' value='" + element['id'] + "'>" +
                                    "<button type='submit' id='prospect_"+element['id']+"' class=\"btn btn-success conversion\">Conversion</button>" +
                                    "</form>";
                            } else if (reqType == "day" && isFinal == 0) {
                                if (element["prisparmoi"]) {
                                    action =
                                        "<form method='post'>" +
                                        "<input type='hidden' class='idProspect' name='idProspect' value='" + element['id'] + "'>" +
                                        "<button type='submit' id='prospect_"+element['id']+"' class=\"btn btn-warning prise \">Reprendre en charge</button>" +
                                        "</form>";
                                } else if (element["prisparqui"] == null) {
                                    action =
                                        "<form method='post'>" +
                                        "<input type='hidden' class='idProspect' name='idProspect' value='" + element['id'] + "'>" +
                                        "<button type='submit' id='prospect_"+element['id']+"' class=\"btn btn-success prise \">Prise en charge</button>" +
                                        "</form>";
                                } else {
                                    action =
                                        "<span id='prospect_"+element['id']+"' class=\"btn btn-error  \">Pris en charge par "+element['prisparqui']+"</span>";
                                }
                            }
                            if (element['idclient']) {
                                card.append("<tr> " +
                                    "<th scope='row' colspan='8'>Pas de résultat</th>" +
                                    "</tr>");
                            } else {
                                card.append("<tr> " +
                                    "<th scope='row'>" + element['id'] + "</th>" +
                                    "<td>"+element['callcenter']+"</td>" +
                                    "<td>" + element['saisiepar'] + "</td>" +
                                    "<td><a href=\"/prospect-details/"+element['id']+"\">" + element['client'] + "</a></td>" +
                                    "<td>" + element['phone'] + "</td>" +
                                    "<td>" + last + "</td>" +
                                    "<td>" + element["commentaire"] + "</td>" +
                                    "<td>" +
                                    action +
                                    "</td>" +
                                    "</tr>");
                            }
                        });
                    } else {
                        card.append("<tr> " +
                            "<th scope='row' colspan='7'>Pas de résultat</th>" +
                            "</tr>");
                    }
                }
            });

        }

    });
}

