

$(document).ready(function() {

    if ($(".page_detail_prospect").length) {

        $('.check_bgt_network_prospect').click(function (e){
            var prospectId = $(this).attr("id").split("_")[1];
            $('body').css("opacity","0.4");

            $.ajax({
                type: "POST",
                url: "/getBouyguesNetworkStatusForProspect",
                data: {prospectId: prospectId},
                error: function(jqXHR, textStatus, errorThrown) {

                    $('body').css("opacity","1");

                    swal({
                        title: "Echec lors de la récupération des informations",
                        text: "",
                        icon: "",
                        buttons: false,
                        dangerMode: true,
                    });

                    console.log(errorThrown);
                },
                success: function(data) {
                    $('body').css("opacity","1");

                    if(data.hasOwnProperty("error")){
                        swal({
                            title: "Echec",
                            text: data["error"],
                            icon: "",
                            buttons: false,
                            dangerMode: true,
                        });
                    }
                    else {
                        console.log(data);
                        let jsonStr = JSON.stringify(data,null,5);
                        $('#modal_bouygues_network_body > pre').append(document.createTextNode(jsonStr));
                        $('#modal_bouygues_network').modal('show');
                    }
                }
            });

        });
    }


    if ($(".page_new_prospect").length || $(".page_edit_prospect").length) {


        $(document).on("change paste keyup","#zip", function () {

            var zipEntered = $.trim($(this).val());

            if(zipEntered.length == 5){

                var reg = new RegExp(/[0-9]{5}/, 'g');
                if (reg.test(zipEntered)) {
                    $.ajax({
                        method: "post",
                        url: "/get_city_by_zip",
                        data: {zip: zipEntered},
                        success: function(response) {
                            if(response.length > 0){

                                var select = "<select name='city' id='city' class='custom-select'>";
                                $.each(response,function (index,element) {
                                    select += "<option value='"+element+"'>"+element+"</option>";
                                });
                                select += '</select>';
                                $("#city").replaceWith(select);
                            }
                        }
                    });
                }
            }
        });


        $('#ccenter').change(function () {

            var selectedCallcenter = $(this).children("option:selected").val();

            $( ".brandcb" ).each(function(index) {
                $(this).hide();
            });

            $(".brandcblst").each(function (index) {
                $(this).prop('checked', false);
            });

            $(".teleopselec").each(function(index) {
                $(this).hide();
            });

            $(".teleop_sel_list").val(-1);

            if(selectedCallcenter != -1){
                $('#brand_cb_'+selectedCallcenter).show();
                $('#teleopselec_'+selectedCallcenter).show();
            }



        });

        $('#mdpaiement').change(function () {
            if($(this).children("option:selected").val() == 1){
                $('.info-iban').show();
            }
            else {
                $('.info-iban').hide();
                $('#iban1').val("");
                $('#iban2').val("");
                $('#iban3').val("");
                $('#iban4').val("");
                $('#iban5').val("");
                $('#iban6').val("");
                $('#iban7').val("");
                $('#bic').val("");
                $('#rib_name').val("");
            }
        });

        $('#iban1').on("change paste keyup", function() {
           if($.trim($(this).val()).length == 4)  {
               $('#iban2').focus();
           }
        });

        $('#iban2').on("change paste keyup", function() {
            if($.trim($(this).val()).length == 4)  {
                $('#iban3').focus();
            }
        });

        $('#iban3').on("change paste keyup", function() {
            if($.trim($(this).val()).length == 4)  {
                $('#iban4').focus();
            }
        });

        $('#iban4').on("change paste keyup", function() {
            if($.trim($(this).val()).length == 4)  {
                $('#iban5').focus();
            }
        });

        $('#iban5').on("change paste keyup", function() {
            if($.trim($(this).val()).length == 4)  {
                $('#iban6').focus();
            }
        });

        $('#iban6').on("change paste keyup", function() {
            if($.trim($(this).val()).length == 4)  {
                $('#iban7').focus();
            }
        });

        $('#iban7').on("change paste keyup", function() {
            if($.trim($(this).val()).length == 3)  {
                $('#bic').focus();
            }
        });

    }

});

