

$(document).ready(function() {

    if ($(".page_detail_client").length) {

        var swal2 = require("sweetalert2");

        $("#chat_history").click(function (e) {
            $("#modal_client_chat_histo").modal('show');
        });

        $('.nav-item').click(function (e){
            var id = $(this).attr('id').split("-")[0];
            $('.tab-pane').removeClass("active");
            $('.tab-pane').removeClass("show");
            $('#etat_'+id).addClass("active");
            $('#etat_'+id).addClass("show");
            $('#contrat_'+id).addClass("active");
            $('#contrat_'+id).addClass("show");
            $('#simdata_'+id).addClass("active");
            $('#simdata_'+id).addClass("show");
            $('#bankdata_'+id).addClass("active");
            $('#bankdata_'+id).addClass("show");
            $('#operatordata_'+id).addClass("active");
            $('#operatordata_'+id).addClass("show");
        });


        $('.check_bgt_network').click(function (e){
            var clientId = $(this).attr("id").split("_")[1];
            $('body').css("opacity","0.4");

            $.ajax({
                type: "POST",
                url: "/getBouyguesNetworkStatus",
                data: {clientId: clientId},
                error: function(jqXHR, textStatus, errorThrown) {

                    $('body').css("opacity","1");

                    swal({
                        title: "Echec lors de la récupération des informations",
                        text: "",
                        icon: "",
                        buttons: false,
                        dangerMode: true,
                    });

                    console.log(errorThrown);
                },
                success: function(data) {
                    $('body').css("opacity","1");

                    if(data.hasOwnProperty("error")){
                        swal({
                            title: "Echec",
                            text: data["error"],
                            icon: "",
                            buttons: false,
                            dangerMode: true,
                        });
                    }
                    else {
                        console.log(data);
                        let jsonStr = JSON.stringify(data,null,5);
                        $('#modal_bouygues_network_body > pre').append(document.createTextNode(jsonStr));
                        $('#modal_bouygues_network').modal('show');
                    }
                }
            });

        });

        $('.rattachement_btn').click(function (e){
            let msisdn = $('#ratt_msisdn').val();
            $('body').css("opacity","0.4");

            $.ajax({
                type: "POST",
                url: "/getRattachementInfo",
                data: {msisdn: msisdn},
                error: function(jqXHR, textStatus, errorThrown) {

                    $('body').css("opacity","1");

                    swal({
                        title: "Echec lors de la récupération des informations",
                        text: "",
                        icon: "",
                        buttons: false,
                        dangerMode: true,
                    });

                    console.log(errorThrown);
                },
                success: function(data) {
                    $('body').css("opacity","1");

                    if(data.hasOwnProperty("error")){
                        swal({
                            title: "Echec",
                            text: data["error"],
                            icon: "",
                            buttons: false,
                            dangerMode: true,
                        });
                    }
                    else {
                        $('#r_firstname').text(data['firstname']);
                        $('#r_lastname').text(data['lastname']);
                        $('#r_client_line_id').val(data['client_line_id']);
                        $('#modal_rattachement').modal('show');
                    }
                }
            });
        });

        $('.dwl_mail').click(function (e){
            var mailId = $(this).attr("id").split("_")[2];
            var filename = $(this).attr("data-filename");
            $('body').css("opacity","0.4");

            $.ajax({
                type: "POST",
                url: "/retrieveMail",
                data: {mail_id: mailId},
                xhrFields: {
                    responseType: "blob"
                },
                error: function(jqXHR, textStatus, errorThrown) {

                    $('body').css("opacity","1");

                    swal({
                        title: "Echec de récupération du fichier",
                        text: "",
                        icon: "",
                        buttons: false,
                        dangerMode: true,
                    });

                    console.log(errorThrown);
                },
                success: function(data) {
                    $('body').css("opacity","1");

                    if (navigator.msSaveOrOpenBlob) {
                        navigator.msSaveOrOpenBlob(data,  filename);
                    } else {
                        var a = document.createElement("a");
                        // Add the element to the DOM for firefox compat
                        document.body.appendChild(a);
                        var url = (window.URL || window.webkitURL).createObjectURL(data);
                        a.href = url;
                        a.download = filename;
                        a.click();
                        //a.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
                        window.URL.revokeObjectURL(url);
                    }
                }
            });
        });

        $('.gen_mail').click(function (e) {
            $('body').css("opacity","0.4");
            var client_id = $('input[name="id_client"]').val();
            var idTpl = $(this).attr("id").split("_")[2];
            var mailname = $(this).text().trim();

            console.log(mailname);

            var client_line_id = $('.nav-link.active').attr("id").split("-")[0];

            $.ajax({
                type: "POST",
                url: "/printMailFromTemplate",
                data: {client_id: client_id,client_line_id:client_line_id, template_id:idTpl},
                xhrFields: {
                    responseType: "blob"
                },
                error: function(jqXHR, textStatus, errorThrown) {

                    $('body').css("opacity","1");

                    swal({
                        title: "Echec de génération du fichier",
                        text: "",
                        icon: "",
                        buttons: false,
                        dangerMode: true,
                    });

                    console.log(errorThrown);

                },
                success: function(data) {
                    $('body').css("opacity","1");

                    if (navigator.msSaveOrOpenBlob) {
                        navigator.msSaveOrOpenBlob(data,  mailname+"_"+client_id+".pdf");
                    } else {
                        var a = document.createElement("a");
                        // Add the element to the DOM for firefox compat
                        document.body.appendChild(a);
                        var url = (window.URL || window.webkitURL).createObjectURL(data);
                        a.href = url;
                        a.download = mailname+"_"+client_id+".pdf";
                        a.click();
                        //a.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
                        window.URL.revokeObjectURL(url);
                    }

                }
            });

        });

        $('.gen_pdf').click(function (e) {

            $('body').css("opacity","0.4");

            var client_id = $('input[name="id_client"]').val();

            var client_line_id = $('.nav-link.active').attr("id").split("-")[0];

            var idElt = $(this).attr("id");

            var url="";
            var doc_title="";
            if(idElt == "gen_get_rio_pdf"){
                url = "/printGetRioPdf";
                doc_title="Courrier_RIO_";
            }
            else if(idElt == "gen_get_annul_pdf"){
                url = "/printAnnulationPdf";
                doc_title="Courrier_annulation_";
            }
            else if(idElt == "gen_get_resil_progr_pdf"){
                url = "/printResiliationProgPdf";
                doc_title="Courrier_resil_programmee_";
            }
            else if(idElt == "gen_get_resil_imm_pdf"){
                url = "/printResiliationImmediatePdf";
                doc_title="Courrier_resil_immediate_";
            }
            else if(idElt == "gen_get_mandat_sepa_pdf"){
                url = "/printMandaSepaPdf";
                doc_title="Courrier_mandat_sepa_";
            }
            else if(idElt == "gen_get_accept_resil_pdf"){
                url = "/printAcceptResilPdf";
                doc_title="Courrier_acceptation_resil_";
            }
            else if(idElt == "gen_get_refus_resil_pdf"){
                url = "/printRefusResilPdf";
                doc_title="Courrier_refus_resil_";
            }
            else if(idElt == "gen_get_refus_resil_mise_a_dispo_tel_pdf"){
                url = "/printRefusResilMiseADispoTelPdf";
                doc_title="Courrier_refus_resil_mise_a_dispo_tel_";
            }
            else if(idElt == "gen_get_sim_initiale_pdf"){
                url = "/printSimInitalePdf";
                doc_title="nouvelle_sim_";
            }
            else if(idElt == "gen_get_sim_renew_pdf"){
                url = "/printSimRenewPdf";
                doc_title="renouvellement_sim_";
            }


            $.ajax({
                type: "POST",
                url: url,
                data: {client_id: client_id,client_line_id:client_line_id},
                xhrFields: {
                    responseType: "blob"
                },
                error: function(jqXHR, textStatus, errorThrown) {

                    $('body').css("opacity","1");

                    swal({
                        title: "Echec de génération du fichier",
                        text: "",
                        icon: "",
                        buttons: false,
                        dangerMode: true,
                    });

                    console.log(errorThrown);

                },
                success: function(data) {
                    $('body').css("opacity","1");

                    if (navigator.msSaveOrOpenBlob) {
                        navigator.msSaveOrOpenBlob(data,  doc_title+client_id+"-cedetel.pdf");
                    } else {
                        var a = document.createElement("a");
                        // Add the element to the DOM for firefox compat
                        document.body.appendChild(a);
                        var url = (window.URL || window.webkitURL).createObjectURL(data);
                        a.href = url;
                        a.download = doc_title+client_id+"-cedetel.pdf";
                        a.click();
                        //a.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
                        window.URL.revokeObjectURL(url);
                    }

                }
            });

        });


        $('.collissimo_suivi').click(function () {
            var client_id = $('input[name="id_client"]').val();
            $('body').css("opacity","0.4");

            $.ajax({
                type: "POST",
                url: "/getSuiviEnvoiCollissimo",
                data: {client_id: client_id},
                dataType : 'json',
                error: function(jqXHR, textStatus, errorThrown) {

                    $('body').css("opacity","1");

                    swal({
                        title: "Echec de vérification de l'envoi",
                        text: "",
                        icon: "",
                        buttons: false,
                        dangerMode: true,
                    });

                    console.log(errorThrown);

                },
                success: function(data) {
                    $('body').css("opacity","1");

                    if(data.hasOwnProperty("error")){
                        swal({
                            title: "Echec de vérification de l'envoi",
                            text: data["error"],
                            icon: "",
                            buttons: false,
                            dangerMode: true,
                        });
                    }
                    else if(data.hasOwnProperty("not_modified")){
                        swal({
                            title: "Statut inchangé",
                            text: "Le statut du retour est le même que celui indiqué sur la fiche client",
                            icon: "",
                            buttons: false,
                            dangerMode: false,
                        });
                    }
                    else if(data.hasOwnProperty("success")){
                        swal({
                            title: "Nouveau Statut !",
                            text: data["success"]["status"]+" ("+data["success"]["date_status"]+")",
                            icon: "",
                            buttons: false,
                            dangerMode: false,
                        });
                    }

                }
            });

        });



        $('.collissimo_retour_suivi').click(function () {
            var client_id = $('input[name="id_client"]').val();
            $('body').css("opacity","0.4");

            $.ajax({
                type: "POST",
                url: "/getSuiviEnvoiCollissimoRetour",
                data: {client_id: client_id},
                dataType : 'json',
                error: function(jqXHR, textStatus, errorThrown) {

                    $('body').css("opacity","1");

                    swal({
                        title: "Echec de vérification de l'envoi",
                        text: "",
                        icon: "",
                        buttons: false,
                        dangerMode: true,
                    });

                    console.log(errorThrown);

                },
                success: function(data) {
                    $('body').css("opacity","1");

                    if(data.hasOwnProperty("error")){
                        swal({
                            title: "Echec de vérification de l'envoi",
                            text: data["error"],
                            icon: "",
                            buttons: false,
                            dangerMode: true,
                        });
                    }
                    else if(data.hasOwnProperty("not_modified")){
                        swal({
                            title: "Statut inchangé",
                            text: "Le statut du retour est le même que celui indiqué sur la fiche client",
                            icon: "",
                            buttons: false,
                            dangerMode: false,
                        });
                    }
                    else if(data.hasOwnProperty("success")){
                        swal({
                            title: "Nouveau Statut !",
                            text: data["success"]["status"]+" ("+data["success"]["date_status"]+")",
                            icon: "",
                            buttons: false,
                            dangerMode: false,
                        });
                    }

                }
            });

        });




        $('.etiq_collissimo').click(function () {
            var client_id = $('input[name="id_client"]').val();
            $('body').css("opacity","0.4");

            $.ajax({
                type: "POST",
                url: "/getEtiqCollissimo",
                data: {client_id: client_id},
                dataType : 'json',
                error: function(jqXHR, textStatus, errorThrown) {

                    $('body').css("opacity","1");

                    swal({
                        title: "Echec de génération du fichier",
                        text: "",
                        icon: "",
                        buttons: false,
                        dangerMode: true,
                    });

                    console.log(errorThrown);

                },
                success: function(data) {
                    $('body').css("opacity","1");

                    if(data.hasOwnProperty("error")){
                        swal({
                            title: "Echec de génération du fichier",
                            text: data["error"],
                            icon: "",
                            buttons: false,
                            dangerMode: true,
                        });
                    }
                    else if(data.hasOwnProperty("success")){
                        var link = document.createElement('a');
                        link.href = data["success"]["pdf"];
                        document.body.appendChild(link);
                        link.click();
                    }

                }
            });
        });


        $('.retour_collissimo').click(function () {
            var client_id = $('input[name="id_client"]').val();
            $('body').css("opacity","0.4");

            $.ajax({
                type: "POST",
                url: "/getRetourCollissimo",
                data: {client_id: client_id},
                dataType : 'json',
                error: function(jqXHR, textStatus, errorThrown) {

                    $('body').css("opacity","1");

                    swal({
                        title: "Echec de génération du fichier",
                        text: "",
                        icon: "",
                        buttons: false,
                        dangerMode: true,
                    });

                    console.log(errorThrown);

                },
                success: function(data) {
                    $('body').css("opacity","1");

                    if(data.hasOwnProperty("error")){
                        swal({
                            title: "Echec de génération du fichier",
                            text: data["error"],
                            icon: "",
                            buttons: false,
                            dangerMode: true,
                        });
                    }
                    else if(data.hasOwnProperty("success")){
                        var link = document.createElement('a');
                        link.href = data["success"]["pdf"];
                        document.body.appendChild(link);
                        link.click();
                    }

                }
            });
        });


        $('.dwld_contrat_btn').click(function () {
            var client_id = $('input[name="id_client"]').val();
            $('body').css("opacity","0.4");

            $.ajax({
                type: "POST",
                url: "/getContract",
                data: {client_id: client_id},
                xhrFields: {
                    responseType: "blob"
                },
                error: function(jqXHR, textStatus, errorThrown) {

                    $('body').css("opacity","1");

                    swal({
                        title: "Echec de génération du fichier",
                        text: "",
                        icon: "",
                        buttons: false,
                        dangerMode: true,
                    });

                    console.log(errorThrown);

                },
                success: function(data) {
                    $('body').css("opacity","1");

                    if (navigator.msSaveOrOpenBlob) {
                        navigator.msSaveOrOpenBlob(data,  client_id+"-cedetel.pdf");
                    } else {
                        var a = document.createElement("a");
                        // Add the element to the DOM for firefox compat
                        document.body.appendChild(a);
                        var url = (window.URL || window.webkitURL).createObjectURL(data);
                        a.href = url;
                        a.download = client_id+"-cedetel.pdf";
                        a.click();
                        //a.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
                        window.URL.revokeObjectURL(url);
                    }

                }
            });
        });


        $(".order_status").change(function(){
            let clientLineid = $(this).attr("id").split("_")[2];
            $('#changerorderstatusbtn_'+clientLineid).prop('disabled',false);
        });


        $('.changer_order_status_btn').click(function () {

            var client_line_id = $(this).attr("id").split("_")[1];
            var client_id = $('input[name="id_client"]').val();
            $('body').css("opacity","0.4");

            console.log(client_line_id);
            console.log(client_id);

            $.ajax({
                type: "POST",
                url: "/changeOrderStatus",
                data: {client_id: client_id, client_line_id:client_line_id, order_status_id: $("#order_status_"+client_line_id).children("option:selected").val()},
                dataType : 'json',
                error: function(jqXHR, textStatus, errorThrown) {

                    $('body').css("opacity","1");

                    swal({
                        title: "Echec lors du changement de status",
                        text: "",
                        icon: "",
                        buttons: false,
                        dangerMode: true,
                    });

                    console.log(errorThrown);

                },
                success: function(data) {
                    $('body').css("opacity","1");
                }
            });
        });


        function formatDate(date) {

            var month = date.getMonth()+1;
            if(month < 10)
                month = "0"+month;

            return date.getDate()+"/"+month+"/"+date.getFullYear()
        }


        $('.exec_remb').submit(function (e) {
            e.preventDefault()

            $('body').css("opacity","0.4");

            $.ajax({
                type: "POST",
                url: $(this).attr('action'),
                dataType : 'json',
                data: $(this).serialize(),
                error: function(jqXHR, textStatus, errorThrown) {

                    $('body').css("opacity","1");

                    console.log(errorThrown);
                },
                success: function(result) {
                    //console.log(result);
                    $('body').css("opacity","1");

                    var response  = result;
                    if(response.hasOwnProperty("error")){
                        $('#title_modal_notif').text("Echec");
                        $('#body_modal_notif').text(response["error"]);
                        $('#modal_notif_client').modal('show');
                    }
                    else {
                        $('#title_modal_notif').text("Réussite");
                        $('#body_modal_notif').text(response["success"]);
                        $('#modal_notif_client').modal('show');
                        setTimeout(
                            function()
                            {
                                location.reload();
                            }, 3000);
                    }
                }
            });

        });





        $('#is_porta').change(function() {
            if($(this).is(":checked")) {
                $("#date_porta_div").show();
                $("#date_exec_div").hide();
            }
            else {
                $("#date_porta_div").hide();
                $("#date_exec_div").show();
            }
        });

        Date.prototype.addDays = function(days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        }

        var dateToday = new Date();

        $("#date_porta").datepicker({
            language: 'fr',
            daysOfWeekDisabled: [0],
            startDate: dateToday,

        });
        $("#date_exec").datepicker({
            language: 'fr',
            startDate: dateToday,
        });

        $("#date_exec").val(formatDate(dateToday));
        $("#date_porta").val(formatDate(dateToday));


        $(".prov_form_centile").submit(function (e) {
            e.preventDefault();

            let clientId = $(this).attr("id").split('_')[2];
            let clientName =$("#synchro_centile_"+clientId).find( "input[name=client_name]").val();

            swal({
                title: "Provisionning Centile",
                text: "Provisionner le client "+clientName+" dans Centile ",
                icon: "",
                buttons: true,
                dangerMode: false,
            })
                .then((confirm) => {
                    if (confirm) {
                        $('body').css("opacity", "0.4");

                        $.ajax({
                            type: "POST",
                            url: $(this).attr('action'),
                            dataType : 'html',
                            data: $(this).serialize(),
                            error: function(jqXHR, textStatus, errorThrown) {

                                $('body').css("opacity","1");

                                swal({
                                    title: "Echec Provisionning Centile",
                                    text: "Problème lors de l'envoi à Centile, contactez votre administrateur",
                                    icon: "",
                                    buttons: false,
                                    dangerMode: true,
                                });

                                console.log(errorThrown.toString());

                            },
                            success: function(result) {
                                //console.log(result);
                                $('body').css("opacity","1");

                                var response  = result;
                                if(response.hasOwnProperty("error")){
                                    swal({
                                        title: "Echec Provisionning Centile",
                                        text: response['error'],
                                        icon: "",
                                        buttons: false,
                                        dangerMode: true,
                                    });
                                }
                                else {
                                    swal({
                                        title: "Succès Provisionning Centile",
                                        text: response['error'],
                                        icon: "",
                                        buttons: false,
                                        dangerMode: false,
                                    });
                                    setTimeout(
                                        function()
                                        {
                                            location.reload();
                                        }, 3000);
                                }
                            }
                        });

                    }
                });


        });


        $('.free_sim').click(function (e) {

            let clientLineid = $(this).attr("id").split("_")[2];

            swal({
                title: "Libérer la SIM",
                text: "La carte sim ne sera plus affectée à ce client et pourra être réutilisée",
                icon: "",
                buttons: true,
                dangerMode: false,
            })
                .then((confirm) => {
                    if (confirm) {
                        $('body').css("opacity", "0.4");

                        $.ajax({
                            type: "POST",
                            url: '/freeSim',
                            data: {clientLineid:clientLineid},
                            dataType : 'html',
                            error: function(jqXHR, textStatus, errorThrown) {

                                $('body').css("opacity","1");

                                swal({
                                    title: "Echec",
                                    text: "Problème lors de la libération de la SIM",
                                    icon: "",
                                    buttons: false,
                                    dangerMode: true,
                                });

                                console.log(errorThrown.toString());

                            },
                            success: function(result) {
                                //console.log(result);
                                $('body').css("opacity","1");

                                var response  = result;
                                if(response.hasOwnProperty("error")){
                                    swal({
                                        title: "Echec",
                                        text: response['error'],
                                        icon: "",
                                        buttons: false,
                                        dangerMode: true,
                                    });
                                }
                                else {
                                    swal({
                                        title: "Succès",
                                        text: "La SIM a été libérée",
                                        icon: "",
                                        buttons: false,
                                        dangerMode: false,
                                    });
                                    setTimeout(
                                        function()
                                        {
                                            location.reload();
                                        }, 3000);
                                }
                            }
                        });

                    }
                });

        });


        $('.repair_cfast_links').click(function (e) {
            let clientLineId = $(this).attr("id").split('_')[2];
            swal({
                title: "Réparer liens CFAST",
                text: "",
                icon: "",
                buttons: true,
                dangerMode: false,
            })
                .then((confirm) => {
                    if (confirm) {

                        $('body').css("opacity", "0.4");

                        $.ajax({
                            type: "POST",
                            url: "/repair_cfast_links",
                            dataType: 'json',
                            data: {clientLineId: clientLineId},
                            error: function (jqXHR, textStatus, errorThrown) {
                                $('body').css("opacity", "1");
                                console.log(errorThrown);
                                alert(errorThrown);
                            },
                            success: function (result) {
                                console.log(result);
                                $('body').css("opacity", "1");

                                if(result.hasOwnProperty("error")){
                                    swal({
                                        title: "Echec",
                                        text: result["error"],
                                        icon: "",
                                        buttons: false,
                                        dangerMode: true,
                                    });
                                }
                                else {
                                    swal({
                                        title: "Succès",
                                        content: result["success"],
                                        icon: "",
                                        buttons: false,
                                        dangerMode: true,
                                    });
                                }
                            }
                        });
                    }
                });

        });


        $('.cfast_logo').click(function (e) {

            let clientLineId = $(this).attr("id").split('_')[2];
            let clientName =$("#synchro_cfast_"+clientLineId).find( "input[name=client_name]").val();

            swal({
                title: "Provisionning CFAST",
                text: "Enregistrer le client "+clientName+" dans CFAST ",
                icon: "",
                buttons: true,
                dangerMode: false,
            })
                .then((confirm) => {
                    if (confirm) {

                        $('body').css("opacity","0.4");

                        $.ajax({
                            type: "POST",
                            url: "/client_info_for_cfast",
                            dataType : 'json',
                            data: {clientLineId:clientLineId},
                            error: function(jqXHR, textStatus, errorThrown) {
                                $('body').css("opacity","1");
                                console.log(errorThrown);
                                alert(errorThrown);
                            },
                            success: function(result) {

                                $('body').css("opacity","1");

                                if(result['has_msisdn']){
                                    $("#is_porta_div").show();
                                    $("#msisdn_info").hide();
                                    if(result['has_rio']){
                                        $("#is_porta").prop('checked', true);
                                        $("#date_porta_div").show();
                                        $("#date_exec_div").hide();
                                    }
                                    else {
                                        $("#is_porta").prop('checked', false);
                                        $("#date_porta_div").hide();
                                        $("#date_exec_div").show();
                                    }

                                    var newSimStr = "";
                                    if(result['new_eit_sim']) {
                                        newSimStr = "<div class='green_color'><span class=\"ti-check\"></span> Fait partie du lot des nouvelles SIM</div>";
                                    }
                                    else {
                                        newSimStr = "<div class='red_color'><span class=\"ti-close\"></span> Ne fait pas partie du lot des nouvelles SIM</div>";
                                    }

                                    var eitValidStr = "";
                                    if(result['sim_cfast_valid']){
                                        eitValidStr = "<div class='green_color'><span class=\"ti-check\"></span> Disponible chez CFAST</div>";
                                    }
                                    else {
                                        eitValidStr = "<div class='red_color'><span class=\"ti-close\"></span>  Indisponible chez CFAST</div>";
                                    }

                                    $("#simsn_info").html("SIMSN : "+result['simsn']+"<br/>"+newSimStr+eitValidStr);

                                    $("#offer_info").html("Offre actuelle : "+result['offer']+"<br/>");
                                    if(result['offer_to_map'] != null)
                                        $("#offer_info").html($("#offer_info").html()+"<div class='green_color'><span class=\"ti-check\"></span> Sera positionné sur le forfait CFAST : "+result['offer_to_map']+"</div>");
                                    else
                                        $("#offer_info").html($("#offer_info").html()+"<div class='red_color'><span class=\"ti-close\"></span> Aucun mapping vers un forfait CFAST n'est défini pour cette offre</div>");

                                    $('#cfast_offer_id').val(result['cfast_offer_id']);
                                    $('#clientLineId').val(result['clientLineId']);
                                    $('#cfast_sim_id').val(result['sim_id_cfast']);

                                    if(result['cfast_offer_id'] !== -1){
                                        $("#offer_info").html($("#offer_info").html()+"<div class='green_color'><span class=\"ti-check\"></span> Offre CFAST : "+result['cfast_offer_id']+"</div>");
                                    }
                                    else {
                                        $("#offer_info").html($("#offer_info").html()+"<div class='red_color'><span class=\"ti-close\"></span> Offre mapping non reconnue par CFAST </div>");
                                    }

                                    if(result['new_eit_sim'] && result['sim_cfast_valid'] && result['offer_to_map'] != null && result['cfast_offer_id'] !== -1){
                                        $("#btn_cfast_send").show();
                                    }
                                    else {
                                        $("#btn_cfast_send").hide();
                                    }
                                }
                                else {
                                    // Pas de MSISDN par de provisionning
                                    $("#is_porta_div").hide();
                                    $("#date_porta_div").hide();
                                    $("#date_exec_div").hide();
                                    $("#msisdn_info").show();

                                }

                                $('#modal_cfast_send_1').modal("show");

                            }
                        });




                        //$("#synchro_cfast_"+clientId).submit();
                    }
                });

        });


        var resConfirmed = false;
        $('#resilisation').on('submit',function (e) {

        if(!resConfirmed){
            e.preventDefault();
            var form = $(this);
            swal({
                title: "Etes vous sur de vouloir résilier ce client ?",
                text: "",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((confirm) => {
                    if (confirm) {
                        resConfirmed = true;
                        form.submit();
                    }
                    else
                        resConfirmed = false;
                });
            }


        });



        $("#send_notif_btn").click(function (e) {
            e.preventDefault();

            $('body').css("opacity","0.4");


            let sms = 0;
            let email = 0;
            if($('#channel_sms').is(":checked")){
                sms = 1;
            }
            if($('#channel_email').is(":checked")){
                email = 1;
            }

            let notif_text = $('#notif_text').val().replace(/\r\n|\r|\n/g,"<br />");

            let client_id = $('input[name="id_client"]').val();
            var client_line_id = $('.nav-link.active').attr("id").split("-")[0];


            if(sms == 0 && email == 0){
                swal({
                    title: "Informations manquantes",
                    text: "Il faut indiquer un canal pour la notification (SMS ou email)",
                    icon: "",
                    buttons: false,
                    dangerMode: true,
                });
                return;
            }

            if(notif_text.length < 3){
                swal({
                    title: "Informations manquantes",
                    text: "Le texte de la notification est trop court",
                    icon: "",
                    buttons: false,
                    dangerMode: true,
                });
                return;
            }

            $.ajax({
                type: "POST",
                url: "/sendManualNotification",
                dataType: 'json',
                data: {sms:sms, email:email,notif_text:notif_text,client_id:client_id,client_line_id:client_line_id},
                error: function(jqXHR, textStatus, errorThrown) {

                    $('body').css("opacity","1");


                    swal({
                        title: "Echec lors de l'envoi de la notification",
                        text: "Problème lors de l'envoi de la notification, contactez votre administrateur",
                        icon: "",
                        buttons: false,
                        dangerMode: true,
                    });

                    console.log(errorThrown);

                },
                success: function(result) {
                    //console.log(result);
                    $('body').css("opacity","1");

                    var response  = result;
                    if(response.hasOwnProperty("error")){
                        swal({
                            title: "Echec lors de l'envoi de la notification",
                            text: response['error'],
                            icon: "",
                            buttons: false,
                            dangerMode: true,
                        });
                    }
                    else {
                        swal({
                            title: "Notification envoyée avec succès",
                            text: response['success'],
                            icon: "",
                            buttons: false,
                            dangerMode: false,
                        });
                        setTimeout(
                            function()
                            {
                                location.reload();
                            }, 3000);
                    }
                }
            });

        });


        $(document).on('click','.disp_notif',function (e) {

            let idNotif = $(this).attr("id").split("_")[2];

            let notifText = $('#notif_cnt_'+idNotif);

            let notifToDisp ="";
            if(notifText.find("table").length > 0){
                let notifTextDiv = notifText.find("table");

                notifTextDiv.find("img").remove();
                notifToDisp = notifTextDiv.html();
            }
            else {
                notifToDisp = notifText.html();
            }



            swal2.fire({
                title: "",
                html: notifToDisp,
                icon: "",
                showConfirmButton: false,
                width: "600px",
                padding: "0",
                /*grow: "fullscreen",*/
                heightAuto: true
            });
        });

        $("#formCfastProv").submit(function (e) {
            e.preventDefault();

            $('body').css("opacity","0.4");

            $.ajax({
                type: "POST",
                url: $(this).attr('action'),
                dataType : 'json',
                data: $(this).serialize(),
                error: function(jqXHR, textStatus, errorThrown) {

                    $('body').css("opacity","1");


                    $('#modal_cfast_send_1').modal("hide");

                    swal({
                        title: "Echec Provisionning CFAST",
                        text: "Problème lors de l'envoi à CFAST, contactez votre administrateur",
                        icon: "",
                        buttons: false,
                        dangerMode: true,
                    });

                    console.log(errorThrown);

                },
                success: function(result) {
                    //console.log(result);
                    $('body').css("opacity","1");

                    $('#modal_cfast_send_1').modal("hide");

                    var response  = result;
                    if(response.hasOwnProperty("error")){
                        swal({
                            title: "Echec Provisionning CFAST",
                            text: response['error'],
                            icon: "",
                            buttons: false,
                            dangerMode: true,
                        });
                    }
                    else {
                        swal({
                            title: "Succès Provisionning CFAST",
                            text: response['error'],
                            icon: "",
                            buttons: false,
                            dangerMode: false,
                        });
                        setTimeout(
                            function()
                            {
                                location.reload();
                            }, 3000);
                    }
                }
            });

        });


        $('.exec_cred, .exec_pay').submit(function (e) {
            e.preventDefault()

            var formClass = $(this).attr("class");
            var titleSwal = "";
            if(formClass == "exec_cred"){
                titleSwal = "Entrer le montant à créditer en € :";
            }
            else if(formClass == "exec_pay"){
                titleSwal = "Entrer le montant à débiter en € :";
            }

            swal(titleSwal, {
                content: "input",
            })
            .then((value) => {

                if($.isNumeric(value)){
                    $("."+formClass+" input[name=amount]").val(value);
                    $('body').css("opacity","0.4");
                    $.ajax({
                        type: "POST",
                        url: $(this).attr('action'),
                        dataType : 'json',
                        data: $(this).serialize(),
                        error: function(jqXHR, textStatus, errorThrown) {

                            $('body').css("opacity","1");

                            console.log(errorThrown);
                        },
                        success: function(result) {
                            //console.log(result);
                            $('body').css("opacity","1");

                            var response  = result;
                            if(response.hasOwnProperty("error")){
                                $('#title_modal_notif').text("Echec");
                                $('#body_modal_notif').text(response["error"]);
                                $('#modal_notif_client').modal('show');
                            }
                            else {
                                $('#title_modal_notif').text("Réussite");
                                $('#body_modal_notif').text(response["success"]);
                                $('#modal_notif_client').modal('show');
                                setTimeout(
                                    function()
                                    {
                                        location.reload();
                                    }, 3000);
                            }
                        }
                    });
                }
                else {
                    swal({
                        title: "Nombre incorrect",
                        text: 'La valeur entrée n\'est pas un nombre (attention le sépareur des cents doit être un point)',
                        icon: "",
                        buttons: false,
                        dangerMode: true,
                    });
                }

            });
        });

        $('.choose_msisdn_conv').click(function (e) {
            e.preventDefault()
            $('body').css("opacity","0.4");

            $.ajax({
                type: "POST",
                url: "/get_available_msisdn",
                dataType : 'json',
                data: $(this).serialize(),
                error: function(jqXHR, textStatus, errorThrown) {

                    $('body').css("opacity","1");

                    console.log(errorThrown);
                },
                success: function(result) {
                    //var response = JSON.parse(result);
                    $('body').css("opacity","1");

                    var str = "";
                    if (result.length > 0) {
                        $.each(result, function (index, element) {
                            if(element['eitStatus'] === "Available")
                                str +="<div class='mt-3 mb-3 ml-3 mr-3 msisdn_sel_conv' id='msisdn_"+element['id']+"'>"+element['number']+"</div>";
                        });
                    }
                    $("#body_modal_comment_conv").html(str);
                    $("#modal_choose_msisdn_conv").modal('show');
                }
            });

        });


        $('.choose_msisdn').click(function (e) {
            e.preventDefault()
            $('body').css("opacity","0.4");
            let clientLineId = $(this).attr("id").split("_")[2];

            $.ajax({
                type: "POST",
                url: "/get_available_msisdn",
                dataType : 'json',
                data: $(this).serialize(),
                error: function(jqXHR, textStatus, errorThrown) {

                    $('body').css("opacity","1");

                    console.log(errorThrown);
                },
                success: function(result) {
                    //var response = JSON.parse(result);
                    $('body').css("opacity","1");

                    var str = "";
                    if (result.length > 0) {
                        $.each(result, function (index, element) {
                            if(element['eitStatus'] === "Available")
                                str +="<div class='mt-3 mb-3 ml-3 mr-3 msisdn_sel' id='msisdn_"+clientLineId+"_"+element['id']+"'>"+element['number']+"</div>";
                        });
                    }
                    $("#body_modal_comment").html(str);
                    $("#modal_choose_msisdn").modal('show');
                }
            });

        });


        $(document).on('click','.msisdn_sel',function (e) {

            let clientLineId = $(this).attr("id").split("_")[1];
            let elementId = $(this).attr("id").split("_")[2];

            $('#msisdn_'+clientLineId).val($(this).text());
            $("#msisdn_"+clientLineId).prop('readonly', true);
            $("#msisdn_cfast_id_"+clientLineId).val(elementId);
            $("#rio_"+clientLineId).prop('readonly', true);
            $("#modal_choose_msisdn").modal('hide');
        });


        $(document).on('click','.msisdn_sel_conv',function (e) {

            let elementId = $(this).attr("id").split("_")[1];

            $('#msisdn').val($(this).text());
            $("#msisdn").prop('readonly', true);
            $("#msisdn_cfast_id").val(elementId);
            $("#rio_mobile").prop('readonly', true);
            $("#modal_choose_msisdn_conv").modal('hide');
        });


        $('.add_comment_btn').click(function (e) {
            $('#modal_comment_client').modal('show');
        });

        $('.add_cb_btn').click(function (e) {
            $('#modal_add_cb').modal('show');
        });

        $("#formAddComment").submit(function (e) {
            if($('#comment_ta').val().trim() === ""){
                $('#err_msg').text("Votre commentaire est vide !");
                e.preventDefault();
            }
            else {
                $('#modal_comment_client').modal('hide');
            }
        });


        var reg = new RegExp('^\\d+$');

        $('#formAddCb').submit(function (e) {
            if($('#numero_cb').val().replace(/ /g, "").length !== 16 || !reg.test($('#numero_cb').val().replace(/ /g, ""))){
                $('#err_msg_cb').text("Numéro de carte bancaire invalide");
                e.preventDefault();
            }
            else if($('#numero_cvv').val().trim().length !== 3 || !reg.test($('#numero_cvv').val().trim())){
                $('#err_msg_cb').text("Numéro CVV invalide");
                e.preventDefault();
            }
            else if($('#mois_fv option:selected').val() === "-1" || $('#annee_fv option:selected').val() === "-1") {
                $('#err_msg_cb').text("Date de fin de validité invalide");
                e.preventDefault();
            }
            else {
                $('#modal_add_cb').modal('hide');
            }

        });

        $('.change_sim_btn').click(function (e) {
            let clientLineId = $(this).attr("id").split("_")[1];
            $("#formChangeSim input[name=client_line_id]").val(clientLineId);
            $('#modal_change_sim').modal('show');
        });

        $('.change_offer_btn').click(function (e) {
            let clientLineId = $(this).attr("id").split("_")[1];
            $("#formChangeOffer input[name=client_line_id]").val(clientLineId);
            $('#modal_change_offer').modal('show');
        });


        $("#formChangeSim").submit(function (e) {
            if($('#simsn').val().trim() === ""){
                $('#err_msg_sim').text("Pas de sim renseignée");
                e.preventDefault();
            }
            else {
                $('#modal_change_sim').modal('hide');
            }
        });


        $( function() {
            var termTemplate = "<span class='ui-autocomplete-term'>%s</span>";

            $( "#simsn" )
                .on( "keydown", function( event ) {
                    if ( event.keyCode === $.ui.keyCode.TAB &&
                        $( this ).autocomplete( "instance" ).menu.active ) {
                        event.preventDefault();
                    }
                })
                .autocomplete({
                    source: "/get_sim_suggestions",
                    minLength: 3
                });
        } );



        $('.change_imei_btn').submit(function (e) {
            e.preventDefault();

            var titleSwal = "Entrez le numéro IMEI";

            swal(titleSwal, {
                content: "input",
            })
                .then((value) => {

                    $(".change_imei_btn input[name=imei]").val(value);
                    $.ajax({
                        type: "POST",
                        url: $(this).attr('action'),
                        dataType : 'json',
                        data: $(this).serialize(),
                        error: function(jqXHR, textStatus, errorThrown) {
                            console.log(errorThrown);
                        },
                        success: function(result) {
                            location.reload();
                        }
                    });
                });
        });



        $('.change_mdp_btn').submit(function (e) {
            e.preventDefault();
            const Swal = require('sweetalert2');
            Swal.fire({
                title: "Choisir le moyen de paiement",
                input: 'radio',
                inputOptions: {
                    '1': 'Prélévement',
                    '2': 'CB',
                    '3': 'Chèque'
                },
                inputValidator: (value) => {
                    if (!value) {
                        return 'Vous devez choisir un moyen de paiement'
                    }
                }
            }).then((result) => {
                $(".change_mdp_btn input[name=payment_type]").val(result.value);
                $.ajax({
                    type: "POST",
                    url: $(this).attr('action'),
                    dataType : 'json',
                    data: $(this).serialize(),
                    error: function(jqXHR, textStatus, errorThrown) {
                        console.log(errorThrown);
                    },
                    success: function(result) {
                        location.reload();
                    }
                });
            })


        });



        $('.change_shortnum_btn').submit(function (e) {
            e.preventDefault();

            var titleSwal = "Entrez le numéro court";

            swal(titleSwal, {
                content: "input",
            })
                .then((value) => {

                    $(".change_shortnum_btn input[name=shortnum]").val(value);
                    $.ajax({
                        type: "POST",
                        url: $(this).attr('action'),
                        dataType : 'json',
                        data: $(this).serialize(),
                        error: function(jqXHR, textStatus, errorThrown) {
                            console.log(errorThrown);
                        },
                        success: function(result) {
                            location.reload();
                        }
                    });
                });
        });



        $('.change_collissimo_btn').submit(function (e) {
            e.preventDefault();

            var titleSwal = "Entrez la Ref Colissimo";

            swal(titleSwal, {
                content: "input",
            })
                .then((value) => {

                    $(".change_collissimo_btn input[name=ref_collissimo]").val(value);
                    $.ajax({
                        type: "POST",
                        url: $(this).attr('action'),
                        dataType : 'json',
                        data: $(this).serialize(),
                        error: function(jqXHR, textStatus, errorThrown) {
                            console.log(errorThrown);
                        },
                        success: function(result) {
                            location.reload();
                        }
                    });
                });
        });


        $('select[name="offer"]').change(function() {
            $('.svc_lst').hide();
            $('.art_lst').hide();
            let offerId  = $(this).children("option:selected").val()
            $('#off_svc_opts_'+offerId).show();
            $('#off_art_opts_'+offerId).show();
        });
    }

});

