

$(document).ready(function() {

    if ($(".depot_bt_file").length) {

        $(function () {
            $("#bloctel_file").change(function () {
                let label = $("label[for='" + $(this).attr('id') + "']");
                if(document.getElementById("bloctel_file").files[0] != null){
                    let fileName = document.getElementById("bloctel_file").files[0].name;
                    label.html(fileName);
                }
                else {
                    label.html("Choisir un fichier");
                }

            });
        });

    }


    if($(".page_bloctel_list").length){

        $('.form_bloctel').submit( function(e) {
            e.preventDefault();

            $('body').css("opacity","0.4");

            $.ajax({
                type: "POST",
                url: $(this).attr('action'),
                dataType : 'json',
                data: $(this).serialize(),
                error: function(jqXHR, textStatus, errorThrown) {

                    $('body').css("opacity","1");

                    console.log(errorThrown);
                },
                success: function(result) {
                    //console.log(result);
                    $('body').css("opacity","1");

                    var response  = result;
                    if(response.hasOwnProperty("error")){
                        $('#title_modal_notif').text("Echec");
                        $('#body_modal_notif').text(response["error"]);
                        $('#modal_notif_btel').modal('show');
                    }
                    else {
                        $('#title_modal_notif').text("Réussite");
                        $('#body_modal_notif').text(response["success"]);
                        $('#modal_notif_btel').modal('show');
                        setTimeout(
                            function()
                            {
                                location.reload();
                            }, 3000);
                    }
                }
            });
        });


        $('.form_bloctel_dwld').submit( function(e) {
            e.preventDefault();
            var filename = $(this).find('input[name="bloctel_file_name"]').val();

            $.ajax({
                method: "post",
                url: $(this).attr('action'),
                data: $(this).serialize(),
                xhrFields: {
                    responseType: "blob"
                },
                success: function(data) {

                    if (navigator.msSaveOrOpenBlob) {
                        navigator.msSaveOrOpenBlob(data,  filename.split(".")[0]+"_OK.CSV");
                    } else {
                        var a = document.createElement("a");
                        // Add the element to the DOM for firefox compat
                        document.body.appendChild(a);
                        var url = (window.URL || window.webkitURL).createObjectURL(data);
                        a.href = url;
                        a.download = filename.split(".")[0]+"_OK.CSV";
                        a.click();
                        //a.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
                        window.URL.revokeObjectURL(url);
                    }

                }
            });

        });



        $(function () {
            $(".tt_bt").tooltip({ boundary: 'window' });
        });


    }

});

