

$(document).ready(function() {

    if ($(".page_new_user").length || $(".page_edit_user").length) {


        $('input[type=radio][name=status_policy]').change(function() {

            if (this.value == 0) {
                $('.statusmanaged').show();
            }
            else {
                $('.statusmanaged').hide();
            }
        });

        $('#ccenter').change(function () {

            var selectedCallcenter = $(this).children("option:selected").val();

            $( ".brandcb" ).each(function(index) {
                $(this).hide();
            });

            $(".brandcblst").each(function (index) {
                $(this).prop('checked', false);
            })

            if(selectedCallcenter != -1){
                $('#brand_cb_'+selectedCallcenter).show();
            }
        });


        $("#username").removeAttr('readonly');
        $("#username").blur();
        $("#username").focus();

        $("#password").removeAttr('readonly');
        $("#password").blur();
        $("#password").focus();




    }

    if ($(".page_list_user").length) {

        $(document).on('click', '.delete-user', function (e) {


            swal({
                title: "Etes vous sur de vouloir supprimer cet utilisateur ?",
                text: "",
                icon: "",
                buttons: true,
                dangerMode: true,
            })
                .then((confirm) => {
                    if (confirm) {
                        var idUser = $(this).attr("id").split("_")[1];
                        $(this).parents('form').attr("action", "/supprimer-utilisateur/"+idUser);
                        $(this).parents('form').submit();
                    }

                });
        });

    }


});

